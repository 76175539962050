import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import api
import { walletTransfer } from '../../api/walletAction';

// import lib
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
   'fromType' : 'profitBal',
   'toType' : 'depositBal',
   'amount' : ''
}

const InternalTransfer = () => {

    // hooks 
    const modalRef = useRef();
    const dispatch = useDispatch();

    // redux-state
    const [ walletDoc ] = useSelector((state) => state.wallet)
    const { currency : currencyDoc } = walletDoc;

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [errors, setErrors] = useState({});

    const { fromType, toType, amount } = formValue;


    const handleCloseModal = () => {
       setFormValue(initialFormValue)
       setErrors({})
       modalRef.current.click()
    };

    const handleChange = (e) => {
       const { name, value } = e.target;
       let formData = { ...formValue, ... { [name] : value } }
       setFormValue(formData);
    };

    const handleSubmit = async () => {
       try {
          const data = { fromType, toType, amount, currencyId : currencyDoc._id }
          const { status, message, errors } = await walletTransfer(data, dispatch);
          if(status === 'success') {
             toastAlert('success', message, 'walletTransfer')
             handleCloseModal()
          } else {
            message && toastAlert('error', message, 'walletTransfer')
            errors && setErrors(errors)
          }
       } catch (err) { }
    };
    // console.log(formValue, 'formValueformValue')
    return (
      <div
        className="modal fade primary_modal transfer_modal"
        data-bs-backdrop="static"
        id="transfer_modal"
        tabindex="-1"
        aria-labelledby="transfer_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="transfer_modalLabel">
                {currencyDoc?.currencySymbol} Transfer
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <form className="assets_form">
                <div className="asset_transfer_flex mb-3">
                  <select 
                    className="form-select primary_asset_select"
                    onChange={handleChange}
                    name="fromType"
                    value={fromType}
                  >
                    <option value="profitBal">Profit Wallet</option>
                    <option value="comissionBal">Comission Wallet</option>
                  </select>
                  <img
                    alt="Nav"
                    className="img-fluid"
                    src={require("../../assets/images/transfer_icon.png")}
                  />
                  <select 
                    className="form-select primary_asset_select"
                    // onChange={handleChange}
                    name="toType"
                    value={toType}
                  >
                    <option value="depositBal">Trading Wallet</option>                                                          
                  </select>
                </div>
                <span className="text-danger">{errors?.fromType}</span>
                { !(errors?.fromType) && <span className="text-danger">{errors?.toType}</span>}
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <label for="withdrawamount" className="form-label">
                      Amount
                    </label>
                    {/*<label className="max_label">Max</label>*/}
                  </div>
                  <div className="asset_deposit_grp">
                    <input
                      type="number"
                      className="form-control primary_asset_inp"
                      name="amount"
                      placeholder="Enter transfer amount"
                      onChange={handleChange}
                      value={amount}
                    />
                    <span className="withdrawal_type">{currencyDoc?.currencySymbol}</span>
                  </div>
                  <span className="text-danger">{errors?.amount}</span>
                </div>
                <div className="deposit_asset_details">
                  <div>
                    <label>Available</label>
                    { walletDoc && <label>{walletDoc[fromType]} {currencyDoc?.currencySymbol}</label> }
                  </div>
                </div>
                <div className="asset_modal_btn_grp mt-4 mb-4">
                  <button
                    className="w-100 primary_btn primary_btn_transform"
                    // data-bs-dismiss="modal"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button 
                    className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
}

export default InternalTransfer;


import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import lib
import { toastAlert } from "../../lib/toastAlert";

// import api
import { dailyReward } from "../../api/referralAction";

import MyTimer from "./countdown";

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Stake = () => {
  // hooks
  const dispatch = useDispatch();

  // redux-state
  const { userName, profile, profitStatus } = useSelector(
    (state) => state.account
  );
  const [walletDoc] = useSelector((state) => state.wallet);
  const {
    startStake,
    endStake,
    startStakeTime,
    endStakeTime,
  } = useSelector((state) => state.referralSettings);
  //const {profitBal,  currency: currencyDoc } = walletDoc;

  // state
  const [loader, setLoader] = useState(false);
  const [btnHide, setBtnHide] = useState(true);
  const [StartDate, setStartDate] = useState();
  const [twoHoursEn, setTwoHoursEn] = useState(false);
  const [msg, setMsg] = useState("");

  console.log("StartDate", StartDate);
  // const [Timer, setTimer] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let { status, success, message, date } = await dailyReward(dispatch);
      console.log("success", success);
      console.log("date", date);
      if (success) {
        const now = new Date(date);
        const afterTwoHour = now.setMinutes(now.getMinutes() + 2);
        // const afterTwoHour = now.setHours(now.getHours() + 2);

        toastAlert("success", message, "dailyReward");
        setStartDate(Number(afterTwoHour));
        setMsg(
          "You have successfully Copied Pro traders.\n After Mentioned time, you will get your Trading Profit"
        );
        setTwoHoursEn(true)
        localStorage.setItem("rewardTime", afterTwoHour);
        // localStorage.setItem('timesUp',"true")
        fetchData();
      } else {
        toastAlert("error", message, "dailyReward");
      }

      // if (status === true) {
      //   toastAlert("success", message, "dailyReward");
      //   checkTime();
      //   fetchData();
      //   setLoader(false);
      // } else if (status === "TIME_NOT_START") {
      //   toastAlert("error", message, "dailyReward");
      //   setLoader(false);
      // } else {
      //   toastAlert("error", message, "dailyReward");
      // }
      setLoader(false);
    } catch (err) {
      console.log("error", err);
    }
  };

  const checkTime = () => {
    const currentMoment = moment.utc();
    const utcCurTime = currentMoment.format("HH:mm");
    console.log("utcCurTime", utcCurTime);

    // 06:00 06:00` A
    if (utcCurTime >= startStakeTime && utcCurTime <= endStakeTime) {
      // if (utcCurTime >= "06:57" && utcCurTime <= "18:00") {
      setBtnHide(false);
    }
  };
  const fetchData = async () => {
    const rewardTime = await localStorage.getItem("rewardTime");
    const now = new Date();
    console.log("rewardTimeeee", rewardTime);
    if (rewardTime && Number(rewardTime) >= now.getTime()) {
      setStartDate(Number(rewardTime));
      setBtnHide(true);
      setTwoHoursEn(true)
      setMsg(
        "You have successfully Copied Pro traders.\n After Mentioned time, you will get your Trading Profit"
      );
      return;
    }
    setMsg(
      "You can Again Start Copy Trading After Some Time. You can only do trading once in a Day"
    );
    // if (startStakeTime) {
    //   let { status, result } = await getServerTime();
    //
    //   if (status == "success") {
    //     let { time, mins } = splitTimeMins(startStakeTime);
    //     let start = setUTCTime(result, time, mins);
    //     console.log("startssssss", start);
    //     let date1 = utcToTimestamp(start);

    //     const now = new Date();
    //     const d = now.setHours(now.getHours());
    //     setStartDate(date1);
    //     setMsg(
    //       "You can Again Start Copy Trading After This Time. You can only do trading once in a Day"
    //     );
    //   }
    // }
  };

  useEffect(() => {
    fetchData();
  }, [startStakeTime]);

  useEffect(() => {
    checkTime();
  }, []);

  return (
    <div className="col-lg-12 col-xl-4">
      <div className="dash_box">
        <div className="trade_user_detail">
          <img
            alt="Trade"
            className="img-fluid"
            src={
              profile
                ? profile
                : require("../../assets/images/copy_usericon.png")
            }
          />
          <h3>{userName}</h3>
        </div>
        <div className="deposit_asset_details">
          <div>
            <label>Profit </label>
            {/* <label>{toFixed(profitBal, 2)} {currencyDoc?.currencySymbol}</label> */}
          </div>
          <div>
            <label>Following Members</label>
            <label>
              {startStake ? startStake : 0}/{endStake ? endStake : 0}
            </label>
          </div>
        </div>
        {/* {profitStatus == "pending" && btnHide === false && ( */}
        <div className="text-center mt-4 mb-4">
          <button
            className={`w-100 primary_btn ${loader ||
              profitStatus === "completed" ||
              (() => {
                const currentMoment = moment.utc();
                const utcCurTime = currentMoment.format("HH:mm");
                // return !(utcCurTime >= "06:57" && utcCurTime <= "18:00");
                return !(
                  utcCurTime >= startStakeTime && utcCurTime <= endStakeTime
                );
              })()
              ? ""
              : "primary_btn_with_bg primary_btn_transform"
              }`}
            type="button"
            onClick={handleSubmit}
            disabled={
              loader ||
              profitStatus === "completed" ||
              (() => {
                const currentMoment = moment.utc();
                const utcCurTime = currentMoment.format("HH:mm");
                // return !(utcCurTime >= "06:57" && utcCurTime <= "18:00");

                return !(
                  utcCurTime >= startStakeTime && utcCurTime <= endStakeTime
                );
              })()
            }
            hidden={twoHoursEn}
          >
            {!loader ? "Start Copy Trading" : "Loading ..."}
          </button>
        </div>
        {/* )} */}

        {profitStatus == "pending" && btnHide === false && (
          <div
            role="alert"
            className="fade primary_warning_alert alert alert-secondary show"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                fill="#38DCA1"
              />
            </svg>
            <div>
              <p>
                You have to daily click start copy trade button to get your
                daily rewards.
              </p>
              {/* <p>You have successfully Copied Pro traders.<br></br> After Mentioned time, you will get your Trading Profit.</p> */}
            </div>
          </div>
        )}

        {!(profitStatus == "pending" && btnHide === false) && (
          <div
            role="alert"
            className="fade primary_warning_alert alert alert-secondary show"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                fill="#38DCA1"
              />
            </svg>
            <div>
              {/* <p>You've Got today's profit. Now Staking will be Open tomorrow again</p> */}
              {/* <p> Start Copy Trading again Tomorrow at 0 gmt.</p> */}
              <p>{msg}</p>
            </div>
          </div>
        )}
        {StartDate && !(profitStatus == "pending" && btnHide === false) && (
          <div
            role="alert"
            className="fade primary_warning_alert alert alert-secondary show align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 100 100"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="#38DCA1"
                stroke="#fff"
                stroke-width="2"
              />
              <line
                x1="50"
                y1="50"
                x2="50"
                y2="30"
                stroke="#fff"
                stroke-width="8"
              />
              <line
                x1="50"
                y1="50"
                x2="70"
                y2="50"
                stroke="#fff"
                stroke-width="8"
              />
              <circle cx="50" cy="50" r="2" fill="#fff" />
            </svg>

            <MyTimer expiryTimestamp={StartDate} fetchData={fetchData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Stake;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

// api
import { forgotPassword } from '../../api/users';

// lib
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
	"email" : ""
}

const ForgotPassForm = () => {
    
    const navigate = useNavigate()

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [errors, setErrors] = useState({});
    const { email, password } = formValue;
	
	const handleChange = (e) => {
		const { name, value, checked } = e.target;
		setFormValue({ ...formValue, ... { [name] : value } })
	}

	const handleSubmit = async (e) => {
		try {

			const { status, message, errors } = await forgotPassword({ email });
			if (status == 'success') {
	            setFormValue(initialFormValue)
	            toastAlert('success', message, 'signup', 'TOP_CENTER');
	            navigate('/trade')
	        } else {
	            if (errors) {
	                setErrors(errors);
	                return false;
	            }
	            toastAlert('error', message, 'signup', 'TOP_CENTER');
	        }
		} catch (err) { }
	}

	return (		
	    <div className="container">            
	        <div className="row">
	          <div className="col-xl-6 m-auto">
	            <div className="dash_box mb-0">
	              <div className="inner_title_wrapper mt-2">
	                <h2 className="inner_title">Forgot Password</h2>
	                <p>Reset Password link has been send to your registered Email Address</p>
	              </div>
	              <form className="auth_form">
	                <div class="mb-3"> 
	                  <label for="exampleFormControlInput1" class="form-label">
	                    Email Address
	                  </label>
	                  <input
	                    type="email"
	                    class="form-control"
	                    id="exampleFormControlInput1"
	                    placeholder="Enter Email Address"
	                    name="email"
	                    onChange={handleChange}
	                    value={email}
	                  />
	                  <span className='text-danger'>{errors?.email}</span>
	                </div>                    
	                <div className="text-center mt-4 mb-4">
	                  <button 
	                  	type="button"
	                  	class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
	                  	onClick={handleSubmit}
	                  >
	                    Submit
	                  </button>
	                </div>                    
	              </form>
	            </div>
	          </div>
	        </div>
	    </div>
	)
}

export default ForgotPassForm;
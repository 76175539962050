import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Images
import copy_icon from "../assets/images/copy_icon.svg";
import { Link } from "react-router-dom";

// import lib
import { dateTimeFormat } from "../lib/dateTimeHelper";

// import api
import { clearNotification } from "../api/users";
 
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Notification() {

  // hooks
  const dispatch = useDispatch();

  // redux-state
  const { unread } = useSelector((state) => state.notice)

  useEffect(() => { 
      return () => {
        clearNotification(dispatch)
      }
  }, [])
  
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper inner_title_wrapper_flex justify-content-between">
            <h2 className="inner_title">Message Center</h2>
            {/*<button className="primary_btn primary_btn_with_bg ">
              Read All
            </button>*/}
          </div>
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_box">
                  <div className="notification_panel_row_panel">
                  {
                     unread && unread.length > 0 && unread.map((item, key) => {
                        return (
                           <div className="notification_panel_row">
                            <div>
                              <span className="unread_dot"></span>
                              <div className="notification_row_content">
                                <h2>{item.title}</h2>
                                <p>{item.description}</p>
                              </div>
                            </div>
                            <p className="notify_date">{dateTimeFormat(item.createdAt)}</p>
                          </div>
                        )
                     })
                  }
                  { unread && unread.length == 0 && <p>No Notification!</p> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}

import React, { useState } from 'react';
// import TradingViewWidget, { Themes } from 'react-tradingview-widget';

// import components
import Stake from './Stake';
import TradingChart from './TradingViewChart'

// document.querySelector("[id='22']")

const Trade = () => {

  const [Status, SetStatus] = useState(false)

  setTimeout(() => {
    SetStatus(true)
  }, 500)

  return (
    <div className="dash_wrapper">
      <div className="row copy_trade_row">
        <div className="col-lg-12 col-xl-8">
          <div className="dash_box">
            {Status && <TradingChart />}
            {/* <TradingViewWidget symbol="BINANCE:BTCUSDT" autosize={true} /> */}
            {/*<img
                    alt="Trade"
                    className="img-fluid"
                    src={require("../../assets/images/trade_chart.png")}
                  />*/}
          </div>
        </div>
        <Stake />
      </div>
    </div>
  )
}

export default Trade;
import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// import components
import ResetPassword from '../components/ResetPassword';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ResetPassPage(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="auth_wrapper">
          <ResetPassword />
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}
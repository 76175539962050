import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import browser from "browser-detect";
import Bowser from "bowser";
import { useDispatch } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// api
import { getGeoInfoData, login } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";


const initialFormValue = {
  email: "",
  password: "",
  // "otpStatus" : false,
  // "otp" : "",
};

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loginHistory, setLoginHistory] = useState({});
  const [errors, setErrors] = useState({});
  const { email, password, } = formValue;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const handleReCaptcha = async () => {
    try {
      if (!executeRecaptcha) {
        toastAlert("error", "Recaptcha required", "recaptcha");
        return "";
      }
      return await executeRecaptcha("login");
    } catch (err) {
      toastAlert("error", "Invalid recaptcha", "recaptcha");
      console.log("err",err)
      return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const captcha = await handleReCaptcha();
      console.log("captcha....",captcha)
      if (!captcha) {
        toastAlert("error", "Invalid recaptcha", "login", "TOP_CENTER");
        return;
      }

      const { status, message, error } = await login(
        { email, password, recaptcha:captcha, loginHistory },
        dispatch
      );
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "signin", "TOP_CENTER");
        navigate("/wallet");
      }
      // else if (status === 'OTP') {
      // 	setFormValue({ ...formValue, ... { 'otpStatus' : true } })
      // 	setErrors({});
      // 	toastAlert('success', message, 'signin', 'TOP_CENTER');
      // }
      else {
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert("error", message, "signin", "TOP_CENTER");
      }
    } catch (err) {}
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      const Browser = Bowser.getParser(window.navigator.userAgent);

      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipAddress: result.ip,
        regionName: result.region,
        broswerName: `${Browser.parsedResult.browser.name}${Browser.parsedResult.browser.version}`,
        isMobile: browserResult.mobile,
        os: Browser.parsedResult.os.name,
      });
    } catch (err) {}
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    //  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 m-auto">
            <div className="dash_box mb-0">
              <div className="inner_title_wrapper mt-2">
                <h2 className="inner_title">Login</h2>
              </div>
              <form className="auth_form">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Email Address"
                    onChange={handleChange}
                    value={email}
                  />
                  <span className="text-danger">{errors?.email}</span>
                </div>
                <div class="mb-3">
                  <label for="inputPassword" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    class="form-control"
                    id="inputPassword"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    value={password}
                  />
                  <span className="text-danger">{errors?.password}</span>
                </div>
                {
                  // (otpStatus === true) && (
                  // 	<div class="mb-3">
                  //       <label for="inputPassword" class="form-label">
                  //         OTP
                  //       </label>
                  //       <input
                  //         type="text"
                  //         class="form-control"
                  //         id="otp"
                  //         placeholder="Enter OTP"
                  //         name='otp'
                  //         onChange={handleChange}
                  //         value={otp}
                  //       />
                  //       <span className="text-danger">{errors?.otp}</span>
                  //     </div>
                  // )
                }
                {/*<div class="form-check">
				          <input
				            class="form-check-input"
				            type="checkbox"
				            id="inlineCheckbox1"
				            value="option1"
				          />
				          <label class="form-check-label" for="inlineCheckbox1">
				            Remember Me
				          </label>
				        </div>*/}
                <div className="text-center mt-4 mb-4">
                  <button
                    type="button"
                    class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                    onClick={handleSubmit}
                  >
                    Login
                  </button>
                </div>
                <p className="text-center">
                  <Link to="/forgot-password">Forgot Password</Link>
                </p>
                <p class="form_note text-center mt-3">
                  Not register yet! <Link to="/register">Sign Up</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      // </GoogleReCaptchaProvider>
  );
};

export default LoginForm;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import lib
import fileObjectUrl from '../../lib/fileObjectUrl';
import { toastAlert } from '../../lib/toastAlert';

// import api
import { updateProfile } from '../../api/users';

const ProfileModal = () => {

  const modalRef = useRef();
  const dispatch = useDispatch();

  // state
  const [usrName, setUsrName] = useState('');
  const [profile, setProfile] = useState('');
  const [errors, setErrors] = useState({});
  
  // redux-state
  const { userName, profile : profileImage } = useSelector((state) => state.account);

  const handleCloseModal = () => {
     setErrors({})
     userName && setUsrName(userName)
     profileImage && setProfile(profileImage)
     modalRef.current.click()
  };

  const handleSubmit = async () => {
     try {
        const formData = new FormData();
        formData.append('userName', usrName)
        formData.append('profile', profile)
        const { status, message, errors } = await updateProfile(formData, dispatch);
        
        if(status === 'success') {
            toastAlert('success', message, 'updateProfile')
            handleCloseModal()
        } else {
          errors && setErrors(errors)
        }
     } catch (err) {}
  }

  useEffect(() => {
     userName && setUsrName(userName)
     profileImage && setProfile(profileImage)
  }, [userName])

	return (
	  <div
        className="modal fade primary_modal"
        data-bs-backdrop="static"
        id="editprofile_modal"
        tabindex="-1"
        aria-labelledby="editprofile_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="editprofile_modalLabel">
                Update Profile
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <form className="assets_form">
                <div className="mb-3">
                  <label for="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control primary_asset_inp"
                    id="username"
                    placeholder="Enter Username"
                    value={usrName}
                    onChange={(e) => setUsrName(e.target.value)}
                  />
                  <span className={'text-danger'} >{errors && errors.userName}</span>
                </div>
                <div className="mb-3">
                  <label for="username" className="form-label">
                    Profile Picture
                  </label>
                  <div class="custom_file_upload btn">
                    Upload
                    <input type="file" name="file" onChange={(e) => setProfile(e.target.files[0])} />
                  </div>
                  {/*<img src={fileObjectUrl(profile)} style={{ 'width': '86px' }} />*/}
                </div>
                <span className={'text-danger'} >{errors && errors.profile}</span>
                <img src={fileObjectUrl(profile)} style={{ 'width': '86px' }} />
                <div className="asset_modal_btn_grp mt-4 mb-4">
                  <button
                    className="w-100 primary_btn primary_btn_transform"
                    // data-bs-dismiss="modal"
                    type="button"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                   onClick={handleSubmit}
                   type="button"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
	)
}

export default ProfileModal;
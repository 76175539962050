import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Images
// import copy_icon from "../assets/images/copy_icon.svg";

// import components
import Trade from '../components/Trade';
import { getSiteSetting } from "../api/users.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function TradePage(props) {
  const [siteDoc, setSiteDoc] = useState({});

  const fetchData = async () => {
    let { status, result } = await getSiteSetting();
    if (status == 'success') {
      setSiteDoc(result)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper inner_title_wrapper_flex justify-content-between align-items-center flex-wrap">
            <h2 className="inner_title">Start Copy Trade</h2>
            {/* <div class="form-check form-switch cusform_form_switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"                
              />
              <label class="form-check-label" for="flexSwitchCheckChecked">
                Trade
              </label>
            </div> */}
            {siteDoc.telegramlink && <a href={siteDoc.telegramlink} target="_blank" className="primary_btn" >Join telegram signal group</a>}
          </div>
          <Trade />
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div >
  );
}

// import config
import axios, { setAuthorization, removeAuthorization, handleResp } from '../config/axios'
import config from '../config';
import { removeAuthToken, setAuthToken } from '../lib/localStorage';
import { toastAlert } from '../lib/toastAlert';

// import action
import { getAssetData } from './walletAction';
import { viewUserProfile } from './users';

// import constant
import { SET_REFERRAL_LIST, SET_REFERRAL_SETTINGS } from '../constant';


export const getRefLevList = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/referral-level`
        });

        dispatch({ 
        	type : SET_REFERRAL_LIST, 
        	data : respData.data.result 
        })
        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getStakingHistory = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getStakingHistory`,
            params
        });

        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}
// 
export const updateReward = async (dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/update-reward`,
    });

    getAssetData(dispatch);
    viewUserProfile(dispatch);
    return {
      status: respData.data.status,
      success: respData.data.success,
      loading: false,
      message: respData.data.message,
      date: respData.data.date,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: err.response.data.status,
      success: err.response.data.success,
      loading: false,
      message: err.response.data.message,
    };
  }
};
export const dailyReward = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/daily-reward`
        });

        getAssetData(dispatch)
        viewUserProfile(dispatch)
        return {
            status: respData.data.status,
            success: respData.data.success,
            loading: false,
            message: respData.data.message,
            date: respData.data.date
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
          status: err.response.data.status,
          success: err.response.data.success,
          loading: false,
          message: err.response.data.message,
        };
    }
}

export const getRefLevSettings = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/referral-settings`
        });

        dispatch({ 
            type : SET_REFERRAL_SETTINGS, 
            data : respData.data.result 
        })
        return {
            status: 'success',
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}
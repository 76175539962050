import React, { useEffect } from "react";

// import components
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed";
import Navbar from "../components/Navbar.js";
import Refer from "../components/Refer/reffer.js";
import { useSelector } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

export default function LevelPage() {
    // dispatch
    const { isAuth } = useSelector((state) => state.auth);
    return (
        <div>
            <ScrollToTopOnMount />
            {isAuth ? <NavbarInner /> : <Navbar />}
            <Refer />
            <Footer />
            <NavbarFixed />
        </div>
    );
}

import React from 'react';

// import components
import UserLevel from './UserLevel';
import LevelList from './LevelList';

const Level = () => {
	return (
		<div className="innerpages_wrapper">
		    <div className="container">
		      <div className="inner_title_wrapper">
		        <h2 className="inner_title">Level</h2>
		      </div>
		      <div className="dash_wrapper">
		        <UserLevel />
		        <LevelList />
		      </div>
		    </div>
	    </div>
	)
}

export default Level;
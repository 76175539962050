// helper route
import ConditionRoute from './components/Route/ConditionRoute';

// import pages
import Home from './pages/home';
import Wallet from './pages/wallet';
import Login from './pages/login';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import Level from './pages/level';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import Trade from './pages/trade';
import Notification from './pages/notification';
import AccountSettings from './pages/account-settings';
import EmailVerification from './pages/EmailVerification';
import ResetPassword from './pages/reset-password';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Referral from './pages/referral';

const initialRoutes = [
    // PUBLIC
    { path: "/", type: "public", component: Home },
    { path: "email-verification/:authToken", type: "public", component:  EmailVerification },
    { path: "withdraw-verification/:authToken", type: "public", component:  EmailVerification },
    { path: "privacy", type: "public", component: Privacy },
    { path: "terms", type: "public", component: Terms },
    
    // AUTH
    { path: "login", type: "auth", component: Login},
    { path: "register", type: "auth", component: Register },
    { path: "forgot-password", type: "auth", component: ForgotPassword },
    { path: "reset-password/:authToken", type: "auth", component: ResetPassword },

    // PRIVATE
    { path: "wallet", type: "private", component: Wallet },
    { path: "level", type: "private", component: Level },
    { path: "deposit", type: "private", component: Deposit },
    { path: "withdraw/:walletType", type: "private", component: Withdraw },
    { path: "trade", type: "private", component: Trade },
    { path: "notifications", type: "private", component: Notification },
    { path: "account-settings", type: "private", component: AccountSettings },
    { path: "referral", type: "public", component: Referral },
]

const routes = initialRoutes.map(({ type, component: Component, ...rest }) => {
    return {
        element : <ConditionRoute type={type} ><Component /></ConditionRoute>,
        ...rest
    }
})

export default routes;
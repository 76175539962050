// import package
import io from 'socket.io-client';

// import lib
import config from './index';

const socket = io(config.SOCKET_URL);

const createSocketUser = (userId) => {
    socket.emit('CREATEROOM', userId)
}

export {
    socket,
    createSocketUser
}
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

// Images
import copy_icon from "../../assets/images/copy_icon.svg";

// import lib
import config from '../../config';

// import lib
import { toFixed } from '../../lib/roundOf';
import { textCopy } from '../../lib/toastAlert';
import { substring } from '../../lib/stringCase';
import { findPerc } from '../../lib/calculation';
import { getSiteSetting } from '../../api/users';


const WalletDetails = () => {

  // redux-state
  const { userCode, refCount, refLevel, refLevelPoints, referralCode } = useSelector((state) => state.account)
  const [walletDoc] = useSelector((state) => state.wallet)
  const levelList = useSelector((state) => state.referralLevel)
  const { depositBal, profitBal, comissionBal, currency: currencyDoc } = walletDoc;

  const referralSetting = useSelector((state) => state.referralSettings);
  const { dailyIncomePerc, refIncomePerc, initialDepositPerc } = referralSetting;

  // state
  const [nextLevAmount, setNextLevAmount] = useState(0);
  const [siteDoc, setSiteDoc] = useState({});

  let percentage = findPerc(refLevelPoints, nextLevAmount)

  useEffect(() => {
    // let nextLev = refLevel + 1;
    if (levelList && levelList.length > 0) {
      let checkLev = levelList.find((el) => el.reachAmount > refLevelPoints);
      if (checkLev) setNextLevAmount(checkLev.reachAmount)
    }
  }, [refLevel, levelList])

  const refLink = `${config.FRONT_URL}/register?ref=${referralCode}`;

  const fetchData = async () => {
    let { status, result } = await getSiteSetting();
    if (status == 'success') {
      setSiteDoc(result)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="row">
      <div className="col-xl-3">
        <div className="row dashbox_left_inner_row">
          <div className="col-md-6 col-lg-6 col-xl-12">
            <div className="dash_box dash_box_left_flex dash_box_left">
              <svg
                width="31"
                height="37"
                viewBox="0 0 31 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.0437 5.03219C6.87673 1.94191 10.9612 0 15.5 0C24.0604 0 31 6.90229 31 15.4167C31 16.0978 30.4449 16.65 29.76 16.65C29.0751 16.65 28.52 16.0978 28.52 15.4167C28.52 8.26457 22.6908 2.46667 15.5 2.46667C11.6875 2.46667 8.25914 4.0949 5.8763 6.69416C5.41483 7.19749 4.63053 7.23344 4.12449 6.77451C3.61844 6.31559 3.5823 5.53551 4.0437 5.03219Z"
                  fill="#626262"
                />
                <path
                  d="M1.24 14.1833C1.92485 14.1833 2.48 14.7355 2.48 15.4167V21.5833C2.48 27.1097 5.96093 31.8305 10.8653 33.6892C11.5052 33.9317 11.8263 34.6443 11.5825 35.2808C11.3386 35.9173 10.6222 36.2367 9.98219 35.9942C4.14811 33.7832 0 28.1666 0 21.5833V15.4167C0 14.7355 0.555167 14.1833 1.24 14.1833Z"
                  fill="#626262"
                />
                <path
                  d="M29.76 20.35C30.4449 20.35 31 20.9022 31 21.5833C31 30.0977 24.0604 37 15.5 37C14.8151 37 14.26 36.4478 14.26 35.7667C14.26 35.0855 14.8151 34.5333 15.5 34.5333C22.6908 34.5333 28.52 28.7354 28.52 21.5833C28.52 20.9022 29.0751 20.35 29.76 20.35Z"
                  fill="#626262"
                />
                <path
                  d="M4.96 15.4167C4.96 9.62691 9.67894 4.93333 15.5 4.93333C21.3211 4.93333 26.04 9.62691 26.04 15.4167V21.5833C26.04 27.3731 21.3211 32.0667 15.5 32.0667C9.67894 32.0667 4.96 27.3731 4.96 21.5833C4.96 20.9022 5.51515 20.35 6.2 20.35C6.88485 20.35 7.44 20.9022 7.44 21.5833C7.44 26.0108 11.0486 29.6 15.5 29.6C19.9514 29.6 23.56 26.0108 23.56 21.5833V15.4167C23.56 10.9892 19.9514 7.4 15.5 7.4C11.0486 7.4 7.44 10.9892 7.44 15.4167C7.44 16.0978 6.88485 16.65 6.2 16.65C5.51515 16.65 4.96 16.0978 4.96 15.4167Z"
                  fill="#626262"
                />
                <path
                  d="M15.5 9.86667C12.4182 9.86667 9.92 12.3515 9.92 15.4167V21.5833C9.92 24.6485 12.4182 27.1333 15.5 27.1333C16.1849 27.1333 16.74 26.5812 16.74 25.9C16.74 25.2188 16.1849 24.6667 15.5 24.6667C13.7879 24.6667 12.4 23.2862 12.4 21.5833V15.4167C12.4 13.7138 13.7879 12.3333 15.5 12.3333C17.2121 12.3333 18.6 13.7138 18.6 15.4167C18.6 16.0978 19.1551 16.65 19.84 16.65C20.5249 16.65 21.08 16.0978 21.08 15.4167C21.08 12.3515 18.5818 9.86667 15.5 9.86667Z"
                  fill="#02D287"
                />
              </svg>
              <div>
                {userCode && <h3>{userCode}</h3>}
                <h4>My Present ID</h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-12">
            <div className="dash_box dash_box_left_flex dash_box_left">
              <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7587 22.7047C14.1707 21.4823 15.0672 19.6655 15.0672 17.6405C15.0672 13.9683 12.1188 10.9807 8.49472 10.9807C4.87062 10.9807 1.92221 13.9683 1.92221 17.6405C1.92221 19.6655 2.8187 21.4822 4.23066 22.7047C1.70265 24.1814 0 26.9286 0 30.067V31.6249C0 32.3844 0.614169 33 1.37183 33H15.6175C16.3751 33 16.9893 32.3844 16.9893 31.6249V30.067C16.9893 26.9287 15.2867 24.1815 12.7587 22.7047ZM8.49472 13.7308C10.6059 13.7308 12.3235 15.4847 12.3235 17.6405C12.3235 19.7962 10.6059 21.5501 8.49472 21.5501C6.38347 21.5501 4.66587 19.7962 4.66587 17.6405C4.66587 15.4847 6.3834 13.7308 8.49472 13.7308ZM14.2456 30.2499H2.74366V30.067C2.74366 26.8884 5.32353 24.3024 8.49472 24.3024C11.6658 24.3024 14.2457 26.8884 14.2457 30.067L14.2456 30.2499Z"
                  fill="#626262"
                />
                <path
                  d="M29.7694 11.724C31.1814 10.5016 32.0779 8.68474 32.0779 6.65981C32.0779 2.98755 29.1295 0 25.5054 0C21.8813 0 18.9329 2.98762 18.9329 6.65981C18.9329 8.68474 19.8294 10.5015 21.2414 11.724C18.7134 13.2007 17.0107 15.9479 17.0107 19.0863V20.6442C17.0107 21.4037 17.6249 22.0193 18.3825 22.0193H32.6282C33.3858 22.0193 34 21.4037 34 20.6442V19.0863C34 15.9479 32.2974 13.2007 29.7694 11.724ZM25.5054 2.75007C27.6166 2.75007 29.3342 4.50397 29.3342 6.65974C29.3342 8.81551 27.6166 10.5694 25.5054 10.5694C23.3942 10.5694 21.6766 8.81551 21.6766 6.65974C21.6766 4.50397 23.3941 2.75007 25.5054 2.75007ZM31.2563 19.2691H19.7544V19.0863C19.7544 15.9076 22.3342 13.3217 25.5054 13.3217C28.6765 13.3217 31.2564 15.9076 31.2564 19.0863L31.2563 19.2691Z"
                  fill="#626262"
                />
                <path
                  d="M26.9889 23.5593C26.4531 23.0222 25.5845 23.0223 25.0489 23.5593C24.6274 23.9816 24.5375 24.6105 24.779 25.121H22.6114C21.8537 25.121 21.2396 25.7366 21.2396 26.4961C21.2396 27.2555 21.8537 27.8711 22.6114 27.8711H24.7791C24.5375 28.3817 24.6275 29.0105 25.0489 29.4329C25.3168 29.7014 25.6678 29.8356 26.019 29.8356C26.3701 29.8356 26.7211 29.7013 26.9891 29.4329L28.9489 27.4684C29.4846 26.9313 29.4846 26.0607 28.9489 25.5237L26.9889 23.5593Z"
                  fill="#02D287"
                />
                <path
                  d="M8.12995 7.23896C8.39787 7.50751 8.74885 7.64171 9.10004 7.64171C9.45109 7.64171 9.80221 7.50744 10.0701 7.23896C10.4915 6.8166 10.5814 6.18771 10.3399 5.67722H12.5075C13.2652 5.67722 13.8794 5.0616 13.8794 4.30215C13.8794 3.5427 13.2652 2.92708 12.5075 2.92708H10.3398C10.5814 2.41651 10.4914 1.78769 10.07 1.36527C9.53422 0.828308 8.66565 0.828308 8.12995 1.36527L6.17015 3.3299C5.63445 3.86694 5.63445 4.73756 6.17015 5.27453L8.12995 7.23896Z"
                  fill="#02D287"
                />
              </svg>
              <div>
                <h3>{refCount && refCount}</h3>
                <h4>Direct Referral</h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-12">
            <div className="dash_box dash_box_left_flex dash_box_left">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.02083 21.75C3.82175 21.7492 4.58963 21.4306 5.15597 20.8643C5.7223 20.298 6.04083 19.5301 6.04167 18.7292V17.5208C6.04167 16.7197 5.7234 15.9513 5.15688 15.3848C4.59037 14.8183 3.82201 14.5 3.02083 14.5C2.21966 14.5 1.4513 14.8183 0.884782 15.3848C0.318265 15.9513 0 16.7197 0 17.5208L0 18.7292C0.00083166 19.5301 0.319364 20.298 0.8857 20.8643C1.45204 21.4306 2.21991 21.7492 3.02083 21.75ZM2.41667 17.5208C2.41667 17.3606 2.48032 17.2069 2.59362 17.0936C2.70693 16.9803 2.8606 16.9167 3.02083 16.9167C3.18107 16.9167 3.33474 16.9803 3.44804 17.0936C3.56135 17.2069 3.625 17.3606 3.625 17.5208V18.7292C3.625 18.8894 3.56135 19.0431 3.44804 19.1564C3.33474 19.2697 3.18107 19.3333 3.02083 19.3333C2.8606 19.3333 2.70693 19.2697 2.59362 19.1564C2.48032 19.0431 2.41667 18.8894 2.41667 18.7292V17.5208Z"
                  fill="#626262"
                />
                <path
                  d="M10.6738 21.7498C11.4747 21.749 12.2426 21.4305 12.809 20.8641C13.3753 20.2978 13.6938 19.5299 13.6947 18.729V12.6873C13.6947 11.8862 13.3764 11.1178 12.8099 10.5513C12.2434 9.98477 11.475 9.6665 10.6738 9.6665C9.87264 9.6665 9.10428 9.98477 8.53777 10.5513C7.97125 11.1178 7.65298 11.8862 7.65298 12.6873V18.729C7.65382 19.5299 7.97235 20.2978 8.53868 20.8641C9.10502 21.4305 9.8729 21.749 10.6738 21.7498ZM10.0697 12.6873C10.0697 12.5271 10.1333 12.3734 10.2466 12.2601C10.3599 12.1468 10.5136 12.0832 10.6738 12.0832C10.8341 12.0832 10.9877 12.1468 11.101 12.2601C11.2143 12.3734 11.278 12.5271 11.278 12.6873V18.729C11.278 18.8892 11.2143 19.0429 11.101 19.1562C10.9877 19.2695 10.8341 19.3332 10.6738 19.3332C10.5136 19.3332 10.3599 19.2695 10.2466 19.1562C10.1333 19.0429 10.0697 18.8892 10.0697 18.729V12.6873Z"
                  fill="#626262"
                />
                <path
                  d="M18.3262 21.7502C19.1271 21.7493 19.895 21.4308 20.4613 20.8645C21.0277 20.2981 21.3462 19.5302 21.347 18.7293V7.85433C21.347 7.05315 21.0288 6.28479 20.4622 5.71828C19.8957 5.15176 19.1274 4.8335 18.3262 4.8335C17.525 4.8335 16.7567 5.15176 16.1901 5.71828C15.6236 6.28479 15.3054 7.05315 15.3054 7.85433V18.7293C15.3062 19.5302 15.6247 20.2981 16.1911 20.8645C16.7574 21.4308 17.5253 21.7493 18.3262 21.7502ZM17.722 7.85433C17.722 7.69409 17.7857 7.54042 17.899 7.42712C18.0123 7.31382 18.166 7.25016 18.3262 7.25016C18.4864 7.25016 18.6401 7.31382 18.7534 7.42712C18.8667 7.54042 18.9304 7.69409 18.9304 7.85433V18.7293C18.9304 18.8896 18.8667 19.0432 18.7534 19.1565C18.6401 19.2698 18.4864 19.3335 18.3262 19.3335C18.166 19.3335 18.0123 19.2698 17.899 19.1565C17.7857 19.0432 17.722 18.8896 17.722 18.7293V7.85433Z"
                  fill="#626262"
                />
                <path
                  d="M25.9792 0C25.1783 0.00083166 24.4104 0.319364 23.844 0.8857C23.2777 1.45204 22.9592 2.21991 22.9583 3.02083V18.7292C22.9583 19.5303 23.2766 20.2987 23.8431 20.8652C24.4096 21.4317 25.178 21.75 25.9792 21.75C26.7804 21.75 27.5487 21.4317 28.1152 20.8652C28.6817 20.2987 29 19.5303 29 18.7292V3.02083C28.9992 2.21991 28.6806 1.45204 28.1143 0.8857C27.548 0.319364 26.7801 0.00083166 25.9792 0ZM26.5833 18.7292C26.5833 18.8894 26.5197 19.0431 26.4064 19.1564C26.2931 19.2697 26.1394 19.3333 25.9792 19.3333C25.8189 19.3333 25.6653 19.2697 25.552 19.1564C25.4387 19.0431 25.375 18.8894 25.375 18.7292V3.02083C25.375 2.8606 25.4387 2.70693 25.552 2.59362C25.6653 2.48032 25.8189 2.41667 25.9792 2.41667C26.1394 2.41667 26.2931 2.48032 26.4064 2.59362C26.5197 2.70693 26.5833 2.8606 26.5833 3.02083V18.7292Z"
                  fill="#626262"
                />
                <path
                  d="M27.7917 26.5832H16.9167C16.5962 26.5832 16.2889 26.4559 16.0623 26.2293C15.8356 26.0027 15.7083 25.6953 15.7083 25.3748C15.7083 25.0544 15.8356 24.747 16.0623 24.5204C16.2889 24.2938 16.5962 24.1665 16.9167 24.1665H27.7917C28.1121 24.1665 28.4195 24.2938 28.6461 24.5204C28.8727 24.747 29 25.0544 29 25.3748C29 25.6953 28.8727 26.0027 28.6461 26.2293C28.4195 26.4559 28.1121 26.5832 27.7917 26.5832Z"
                  fill="#02D287"
                />
                <path
                  d="M12.0833 26.5832H1.20833C0.887863 26.5832 0.580519 26.4559 0.353913 26.2293C0.127306 26.0027 0 25.6953 0 25.3748C0 25.0544 0.127306 24.747 0.353913 24.5204C0.580519 24.2938 0.887863 24.1665 1.20833 24.1665H12.0833C12.4038 24.1665 12.7111 24.2938 12.9378 24.5204C13.1644 24.747 13.2917 25.0544 13.2917 25.3748C13.2917 25.6953 13.1644 26.0027 12.9378 26.2293C12.7111 26.4559 12.4038 26.5832 12.0833 26.5832Z"
                  fill="#02D287"
                />
                <path
                  d="M14.5 29C13.783 29 13.0822 28.7874 12.4861 28.3891C11.8899 27.9908 11.4253 27.4246 11.1509 26.7622C10.8766 26.0998 10.8048 25.371 10.9447 24.6678C11.0845 23.9646 11.4298 23.3187 11.9367 22.8117C12.4437 22.3048 13.0896 21.9595 13.7928 21.8197C14.496 21.6798 15.2248 21.7516 15.8872 22.0259C16.5496 22.3003 17.1158 22.7649 17.5141 23.3611C17.9124 23.9572 18.125 24.658 18.125 25.375C18.1239 26.3361 17.7417 27.2575 17.0621 27.9371C16.3825 28.6167 15.4611 28.9989 14.5 29ZM14.5 24.1667C14.261 24.1667 14.0274 24.2375 13.8287 24.3703C13.63 24.5031 13.4751 24.6918 13.3836 24.9126C13.2922 25.1334 13.2683 25.3763 13.3149 25.6107C13.3615 25.8451 13.4766 26.0604 13.6456 26.2294C13.8146 26.3984 14.0299 26.5135 14.2643 26.5601C14.4987 26.6067 14.7416 26.5828 14.9624 26.4914C15.1832 26.3999 15.3719 26.245 15.5047 26.0463C15.6375 25.8476 15.7083 25.614 15.7083 25.375C15.708 25.0546 15.5806 24.7474 15.3541 24.5209C15.1276 24.2944 14.8204 24.167 14.5 24.1667Z"
                  fill="#02D287"
                />
              </svg>
              <div>
                <h3>{refLevel && refLevel}</h3>
                <h4>Level</h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-12">
            <div className="dash_box dash_box_left">
              <h4 className="text-start">Affiliate Link</h4>
              {refLink &&
                <div className="affiliate_inpgrp">
                  <input
                    className=""
                    value={substring(refLink, 27)}
                  />
                  <img src={copy_icon} alt="Copy" className="copy_icon" onClick={() => textCopy(refLink)} />
                </div>
              }
              <ul className="affiliate_list">
                {siteDoc && siteDoc.facebookLink && <li>
                  <a href={siteDoc.facebookLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.twitterUrl && <li>
                  <a href={siteDoc.twitterUrl} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.telegramlink && <li>
                  <a href={siteDoc.telegramlink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.linkedinLink && <li>
                  <a href={siteDoc.linkedinLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.whatsappLink && <li>
                  <a href={siteDoc.whatsappLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                  </a>
                </li>}
              </ul>

              <div className="mt-3 dash_box_right">
                {
                  initialDepositPerc &&
                  <div>
                    <h4>Direct Referral Income - <span style={{ color: '#212529' }}>{initialDepositPerc} %</span></h4>
                  </div>
                }
                {
                  refIncomePerc &&
                  <div >
                    <h4>Daily Referral Income - <span style={{ color: '#212529' }}>{refIncomePerc} %</span></h4>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-9">
        <div className="row">
          <div className="col-md-6 col-xl-4 col-lg-4">
            <div className="dash_box dash_box_right">
              <div className="dash_box_right_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M17.8092 23.9886C16.2685 23.9886 14.7909 23.3589 13.7015 22.2379C12.612 21.117 12 19.5967 12 18.0114C12 17.5576 12.1752 17.1224 12.4871 16.8015C12.7989 16.4806 13.2219 16.3004 13.6629 16.3004C14.104 16.3004 14.527 16.4806 14.8388 16.8015C15.1507 17.1224 15.3259 17.5576 15.3259 18.0114C15.3259 18.5222 15.4734 19.0216 15.7497 19.446C16.026 19.8704 16.4186 20.2008 16.8778 20.3952C17.3369 20.5897 17.8418 20.6394 18.3284 20.538C18.815 20.4367 19.2614 20.1889 19.6109 19.8261C19.9604 19.4633 20.1973 19.0018 20.2914 18.5002C20.3856 17.9986 20.3328 17.4796 20.1397 17.009C19.9467 16.5383 19.6221 16.1373 19.2072 15.8568C18.7922 15.5763 18.3057 15.4289 17.8092 15.4335C17.3682 15.4335 16.9452 15.2532 16.6333 14.9323C16.3215 14.6114 16.1463 14.1762 16.1463 13.7224C16.1463 13.2686 16.3215 12.8334 16.6333 12.5126C16.9452 12.1917 17.3682 12.0114 17.8092 12.0114C18.6038 11.9609 19.3999 12.0784 20.1487 12.3565C20.8975 12.6346 21.583 13.0675 22.1629 13.6286C22.7429 14.1896 23.2051 14.8669 23.5211 15.6187C23.837 16.3705 24 17.1809 24 18C24 18.8191 23.837 19.6295 23.5211 20.3813C23.2051 21.1331 22.7429 21.8104 22.1629 22.3714C21.583 22.9325 20.8975 23.3654 20.1487 23.6435C19.3999 23.9216 18.6038 24.0391 17.8092 23.9886Z"
                    fill="#21D796"
                  />
                  <path
                    d="M18 32.6482C20.8977 32.6482 23.7303 31.7882 26.1397 30.1769C28.5491 28.5656 30.427 26.2754 31.5359 23.5959C32.6448 20.9164 32.9349 17.968 32.3696 15.1235C31.8043 12.279 30.4089 9.66609 28.3599 7.6153C26.3109 5.56452 23.7003 4.16791 20.8583 3.6021C18.0162 3.03629 15.0704 3.32668 12.3932 4.43656C9.71605 5.54644 7.42785 7.42595 5.81796 9.83743C4.20807 12.2489 3.34879 15.084 3.34879 17.9843C3.34517 19.9795 3.75214 21.954 4.54435 23.7849C5.33657 25.6158 6.49704 27.2638 7.95345 28.6261C8.18891 28.8615 8.5081 28.9937 8.84089 28.9937C9.17368 28.9937 9.49286 28.8615 9.72833 28.6261C9.9635 28.3904 10.0956 28.071 10.0956 27.7379C10.0956 27.4048 9.9635 27.0854 9.72833 26.8497C8.50501 25.7209 7.52947 24.3499 6.86362 22.8238C6.19777 21.2976 5.85614 19.6496 5.86042 17.9843C5.86042 15.5812 6.57239 13.2321 7.9063 11.234C9.24021 9.23595 11.1362 7.67864 13.3544 6.75903C15.5726 5.83941 18.0134 5.5988 20.3683 6.06761C22.7231 6.53643 24.8862 7.69362 26.5839 9.39284C28.2817 11.0921 29.4378 13.257 29.9063 15.6139C30.3747 17.9708 30.1343 20.4138 29.2154 22.6339C28.2966 24.8541 26.7407 26.7517 24.7443 28.0867C22.748 29.4218 20.4009 30.1344 18 30.1344C17.6669 30.1344 17.3475 30.2668 17.112 30.5026C16.8765 30.7383 16.7442 31.058 16.7442 31.3913C16.7442 31.7247 16.8765 32.0444 17.112 32.2801C17.3475 32.5158 17.6669 32.6482 18 32.6482Z"
                    fill="#626262"
                  />
                  <path
                    d="M34.744 5.83423C34.909 5.83501 35.0725 5.80279 35.2249 5.73948C35.3773 5.67617 35.5155 5.58303 35.6314 5.46553C35.8666 5.22986 35.9987 4.9104 35.9987 4.57732C35.9987 4.24423 35.8666 3.92477 35.6314 3.6891L32.2826 0.337341C32.0445 0.11532 31.7297 -0.00554946 31.4043 0.000195822C31.079 0.0059411 30.7686 0.137853 30.5385 0.36814C30.3084 0.598428 30.1766 0.909111 30.1709 1.23474C30.1651 1.56036 30.2859 1.87551 30.5077 2.11377L33.8566 5.46553C33.9725 5.58303 34.1107 5.67617 34.2631 5.73948C34.4155 5.80279 34.579 5.83501 34.744 5.83423Z"
                    fill="#626262"
                  />
                  <path
                    d="M18 5.83415L34.744 5.83423C35.0771 5.83423 35.3959 5.70125 35.6314 5.46553C35.867 5.22982 36 4.91059 36 4.57724C36 4.24388 35.867 3.92482 35.6314 3.6891C35.3959 3.45338 35.0772 3.32033 34.7442 3.32033H18C17.6669 3.32033 17.3475 3.45275 17.112 3.68847C16.8765 3.92418 16.7442 4.24388 16.7442 4.57724C16.7442 4.91059 16.8765 5.23029 17.112 5.46601C17.3475 5.70172 17.6669 5.83415 18 5.83415Z"
                    fill="#626262"
                  />
                  <path
                    d="M4.6046 36C4.7696 36.0008 4.93309 35.9685 5.08549 35.9052C5.23788 35.8419 5.3761 35.7488 5.49205 35.6313C5.72722 35.3956 5.85931 35.0762 5.85931 34.7431C5.85931 34.41 5.72722 34.0905 5.49205 33.8549L2.1432 30.5031C1.90514 30.2811 1.59027 30.1602 1.26493 30.166C0.93959 30.1717 0.629177 30.3036 0.39909 30.5339C0.169003 30.7642 0.0372086 31.0749 0.0314713 31.4005C0.0257301 31.7261 0.146492 32.0413 0.36832 32.2795L3.71716 35.6313C3.8331 35.7488 3.97132 35.8419 4.12372 35.9052C4.27611 35.9685 4.4396 36.0008 4.6046 36Z"
                    fill="#626262"
                  />
                  <path
                    d="M1.25581 32.6483L18 32.6482C18.333 32.6482 18.6525 32.5159 18.888 32.2802C19.1235 32.0445 19.2558 31.7248 19.2558 31.3914C19.2558 31.0581 19.1235 30.7384 18.888 30.5027C18.6525 30.267 18.333 30.1344 18 30.1344L1.25581 30.1345C0.922749 30.1345 0.603329 30.267 0.367821 30.5027C0.132309 30.7384 0 31.0581 0 31.3914C0 31.7248 0.132809 32.0438 0.36832 32.2795C0.603832 32.5152 0.922749 32.6483 1.25581 32.6483Z"
                    fill="#626262"
                  />
                </svg>
                <h4>Trading Balance</h4>
              </div>
              <div className="dash_box_right_bottom">
                {
                  depositBal &&
                  <h2>
                    {toFixed(depositBal, 2)} <span>{currencyDoc?.currencySymbol}</span>
                  </h2>
                }
                <div className="button_grp">
                  <Link
                    to="/deposit"
                    className="primary_btn primary_btn_transform"
                  >
                    Deposit
                  </Link>
                  <Link
                    to="/withdraw/depositBal"
                    className="primary_btn primary_btn_transform"
                  >
                    Withdraw
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 col-lg-4">
            <div className="dash_box dash_box_right">
              <div className="dash_box_right_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M2.92602 20.9521C2.65953 20.9517 2.39992 20.8676 2.18389 20.7116C1.96785 20.5555 1.80634 20.3356 1.72219 20.0827C1.66207 19.9194 1.6357 19.7456 1.64465 19.5718C1.6536 19.398 1.6977 19.2278 1.77427 19.0715C1.85085 18.9152 1.95832 18.7761 2.09018 18.6625C2.22204 18.549 2.37556 18.4633 2.54146 18.4107C7.70697 16.7884 12.6712 14.584 17.3385 11.8398C22.7517 8.66749 27.7263 4.80021 32.1356 0.336557C32.3733 0.115052 32.6877 -0.00553656 33.0126 0.000195366C33.3375 0.00592729 33.6474 0.137533 33.8772 0.367285C34.1069 0.597038 34.2385 0.906999 34.2443 1.23187C34.25 1.55674 34.1294 1.87115 33.9079 2.10886C29.3464 6.72393 24.2036 10.7257 18.6092 14.0134C13.7796 16.8455 8.64808 19.128 3.31057 20.8184L2.92602 20.9521Z"
                    fill="#21D796"
                  />
                  <path
                    d="M33.0217 9.24824C32.6905 9.24391 32.3741 9.1104 32.1398 8.87617C31.9056 8.64193 31.7721 8.32548 31.7678 7.99425V2.5603H26.3338C26.0012 2.5603 25.6823 2.42818 25.4471 2.19301C25.2119 1.95784 25.0798 1.63889 25.0798 1.30631C25.0798 0.97373 25.2119 0.654773 25.4471 0.419604C25.6823 0.184436 26.0012 0.0523196 26.3338 0.0523196H33.0217C33.353 0.05665 33.6694 0.190157 33.9037 0.424393C34.1379 0.658629 34.2714 0.975076 34.2757 1.30631V7.99425C34.2714 8.32548 34.1379 8.64193 33.9037 8.87617C33.6694 9.1104 33.353 9.24391 33.0217 9.24824Z"
                    fill="#21D796"
                  />
                  <path
                    d="M33.8576 36H28.8417C28.2874 36 27.7558 35.7798 27.3638 35.3879C26.9719 34.9959 26.7517 34.4643 26.7517 33.91V15.5182C26.7517 14.9639 26.9719 14.4323 27.3638 14.0404C27.7558 13.6484 28.2874 13.4282 28.8417 13.4282H33.8576C34.4119 13.4282 34.9435 13.6484 35.3355 14.0404C35.7274 14.4323 35.9476 14.9639 35.9476 15.5182V33.91C35.9476 34.4643 35.7274 34.9959 35.3355 35.3879C34.9435 35.7798 34.4119 36 33.8576 36ZM29.2597 33.492H33.4396V15.9362H29.2597V33.492Z"
                    fill="#626262"
                  />
                  <path
                    d="M20.4818 36.0001H15.4658C14.9115 36.0001 14.3799 35.7799 13.988 35.3879C13.596 34.996 13.3759 34.4644 13.3759 33.9101V22.2062C13.3759 21.6519 13.596 21.1203 13.988 20.7284C14.3799 20.3364 14.9115 20.1162 15.4658 20.1162H20.4818C21.0361 20.1162 21.5677 20.3364 21.9596 20.7284C22.3516 21.1203 22.5718 21.6519 22.5718 22.2062V33.9101C22.5718 34.4644 22.3516 34.996 21.9596 35.3879C21.5677 35.7799 21.0361 36.0001 20.4818 36.0001ZM15.8838 33.4921H20.0638V22.6242H15.8838V33.4921Z"
                    fill="#626262"
                  />
                  <path
                    d="M7.10594 35.9998H2.08998C1.53568 35.9998 1.00409 35.7797 0.612141 35.3877C0.220194 34.9958 0 34.4642 0 33.9099V25.5499C0 24.9956 0.220194 24.464 0.612141 24.0721C1.00409 23.6802 1.53568 23.46 2.08998 23.46H7.10594C7.66023 23.46 8.19183 23.6802 8.58378 24.0721C8.97572 24.464 9.19592 24.9956 9.19592 25.5499V33.9099C9.19592 34.4642 8.97572 34.9958 8.58378 35.3877C8.19183 35.7797 7.66023 35.9998 7.10594 35.9998ZM2.50798 33.4919H6.68794V25.9679H2.50798V33.4919Z"
                    fill="#626262"
                  />
                </svg>
                <h4>Profit Balance</h4>
              </div>
              <div className="dash_box_right_bottom">
                {
                  profitBal &&
                  <h2>
                    {toFixed(profitBal, 2)} <span>{currencyDoc?.currencySymbol}</span>
                  </h2>
                }
                <div className="button_grp">
                  <Link
                    to="/withdraw/profitBal"
                    className="primary_btn primary_btn_transform"
                  >
                    Withdraw
                  </Link>
                  <button
                    className="primary_btn primary_btn_transform"
                    data-bs-toggle="modal"
                    data-bs-target="#transfer_modal"
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 col-lg-4">
            <div className="dash_box dash_box_right">
              <div className="dash_box_right_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M18.0033 10.882C17.8208 10.8825 17.6405 10.8431 17.4748 10.7667C17.3091 10.6904 17.162 10.5788 17.0438 10.4398C16.9256 10.3009 16.8391 10.1378 16.7903 9.96199C16.7416 9.78618 16.7317 9.60185 16.7614 9.42184L17.3811 5.70345C17.5418 4.20076 18.2294 2.80351 19.322 1.75948C20.4147 0.715448 21.8418 0.0921091 23.3502 0C23.9318 0.0146065 24.5036 0.153659 25.0269 0.407794C25.5503 0.661929 26.0131 1.02525 26.3843 1.47329C26.8225 1.95075 27.1581 2.51298 27.3704 3.12526C27.5828 3.73754 27.6673 4.38686 27.6188 5.0331C27.3678 7.09669 25.4555 8.09742 23.6053 9.06544C21.9113 9.81556 20.1526 10.4078 18.3499 10.8347C18.2371 10.8666 18.1205 10.8825 18.0033 10.882ZM23.3502 2.51163C22.4385 2.60903 21.5899 3.02297 20.9519 3.68145C20.3138 4.33993 19.9269 5.20125 19.8583 6.11555L19.5664 7.86682C20.5537 7.6126 21.5158 7.26945 22.4411 6.84157C23.8326 6.11228 25.0336 5.48437 25.1252 4.73057C25.1322 4.13987 24.9097 3.56949 24.5046 3.13953C24.3659 2.9609 24.1918 2.8129 23.9931 2.70483C23.7944 2.59677 23.5756 2.53099 23.3502 2.51163Z"
                    fill="#21D796"
                  />
                  <path
                    d="M17.9999 10.8837C17.8829 10.8836 17.7665 10.8671 17.6541 10.8347C15.8497 10.4089 14.0887 9.81665 12.3938 9.0654C10.5444 8.09901 8.63203 7.0983 8.38103 5.03471C8.3324 4.3882 8.41687 3.73859 8.62921 3.12602C8.84156 2.51344 9.17727 1.95092 9.6156 1.47322C9.98675 1.02519 10.4496 0.661883 10.973 0.40776C11.4963 0.153636 12.0681 0.0145959 12.6497 0C14.158 0.0924783 15.5849 0.715942 16.6775 1.75991C17.7701 2.80387 18.4578 4.20092 18.6189 5.70349L19.2386 9.42187C19.2684 9.60172 19.2587 9.78591 19.2102 9.96164C19.1617 10.1374 19.0755 10.3004 18.9577 10.4395C18.8398 10.5786 18.6931 10.6904 18.5277 10.7671C18.3624 10.8438 18.1823 10.8836 17.9999 10.8837ZM12.6497 2.51163C12.4243 2.53098 12.2054 2.59676 12.0068 2.70482C11.8081 2.81289 11.6339 2.96089 11.4952 3.13953C11.0898 3.56996 10.8673 4.14095 10.8747 4.73219C10.9663 5.48437 12.1673 6.11392 13.5588 6.83993L13.5596 6.84161C14.4841 7.2711 15.4461 7.61484 16.4334 7.8685L16.1415 6.11558C16.0728 5.20133 15.6858 4.34008 15.0479 3.68162C14.4099 3.02317 13.5613 2.60917 12.6497 2.51163Z"
                    fill="#21D796"
                  />
                  <path
                    d="M30.5581 36.0002H5.44183C4.66497 35.9992 3.92021 35.6902 3.37088 35.1409C2.82156 34.5916 2.51254 33.8468 2.5116 33.0699V14.6513C2.51169 14.3183 2.64402 13.9989 2.87951 13.7634C3.11501 13.5279 3.43438 13.3956 3.76741 13.3955H32.2325C32.5656 13.3956 32.8849 13.5279 33.1204 13.7634C33.3559 13.9989 33.4883 14.3183 33.4883 14.6513V33.0699C33.4874 33.8468 33.1784 34.5916 32.6291 35.1409C32.0797 35.6902 31.335 35.9992 30.5581 36.0002ZM5.02322 15.9071V33.0699C5.02344 33.1809 5.06761 33.2872 5.14607 33.3657C5.22452 33.4441 5.33087 33.4883 5.44183 33.4885H30.5581C30.6691 33.4883 30.7754 33.4441 30.8539 33.3657C30.9323 33.2872 30.9765 33.1809 30.9767 33.0699V15.9071H5.02322Z"
                    fill="#626262"
                  />
                  <path
                    d="M33.907 15.907H2.09302C1.53808 15.9064 1.00601 15.6858 0.6136 15.2934C0.221194 14.9009 0.000514275 14.3689 0 13.8139V10.4651C0.000514275 9.91015 0.221194 9.37808 0.6136 8.98567C1.00601 8.59326 1.53808 8.37258 2.09302 8.37207H33.907C34.4619 8.37258 34.994 8.59326 35.3864 8.98567C35.7788 9.37808 35.9995 9.91015 36 10.4651V13.8139C35.9995 14.3689 35.7788 14.9009 35.3864 15.2934C34.994 15.6858 34.4619 15.9064 33.907 15.907ZM2.51163 13.3953H33.4884V10.8837H2.51163V13.3953Z"
                    fill="#626262"
                  />
                  <path
                    d="M21.3489 36H14.6512C14.3182 35.9999 13.9988 35.8675 13.7633 35.6321C13.5278 35.3966 13.3955 35.0772 13.3954 34.7442V9.62788C13.3955 9.29485 13.5278 8.97548 13.7633 8.73999C13.9988 8.5045 14.3182 8.37216 14.6512 8.37207H21.3489C21.6819 8.37216 22.0013 8.5045 22.2368 8.73999C22.4723 8.97548 22.6046 9.29485 22.6047 9.62788V14.6511C22.6047 14.9842 22.4724 15.3036 22.2369 15.5391C22.0014 15.7746 21.6819 15.907 21.3489 15.907C21.0158 15.907 20.6964 15.7746 20.4609 15.5391C20.2254 15.3036 20.0931 14.9842 20.0931 14.6511V10.8837H15.907V33.4883H20.0931V19.6744C20.0931 19.3413 20.2254 19.0219 20.4609 18.7864C20.6964 18.5509 21.0158 18.4186 21.3489 18.4186C21.6819 18.4186 22.0014 18.5509 22.2369 18.7864C22.4724 19.0219 22.6047 19.3413 22.6047 19.6744V34.7442C22.6046 35.0772 22.4723 35.3966 22.2368 35.6321C22.0013 35.8675 21.6819 35.9999 21.3489 36Z"
                    fill="#626262"
                  />
                </svg>
                <h4>Referral Commission</h4>
              </div>
              <div className="dash_box_right_bottom">
                {
                  comissionBal &&
                  <h2>
                    {toFixed(comissionBal, 2)} <span>{currencyDoc?.currencySymbol}</span>
                  </h2>
                }

                <div className="button_grp">
                  <Link
                    to="/withdraw/comissionBal"
                    className="primary_btn primary_btn_transform"
                  >
                    Withdraw
                  </Link>
                  <button
                    className="primary_btn primary_btn_transform"
                    data-bs-toggle="modal"
                    data-bs-target="#transfer_modal"
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="dash_box dash_box_right">
              <h5>Level {refLevel + 1}</h5>
              {percentage &&
                <div className="custom_progress">
                  <div className="progress">
                    <div
                      class={`progress-bar w-${percentage}`}
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="10"
                      aria-valuemin="10"
                      aria-valuemax="100"
                      style={{ 'width': `${percentage}%` }}
                    ></div>
                  </div>
                  {refLevelPoints && nextLevAmount &&
                    <div className="progress_note">
                      <label>Progress towards level amount achieve</label>
                      <label>{refLevelPoints} {currencyDoc?.currencySymbol} / {nextLevAmount} {currencyDoc?.currencySymbol}</label>
                    </div>
                  }
                  <div className="progress_value">{toFixed(percentage, 0)}%</div>
                </div>
              }
            </div>
            <div className="dash_box dash_box_right dash_box_right_large">
              <div className="dash_box_right_large_single">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="41"
                  viewBox="0 0 35 41"
                  fill="none"
                >
                  <path
                    d="M27.0236 31.1895H25.4362H18.3332V28.5471C18.9441 28.7033 19.7414 28.8494 20.5898 28.8494C21.4732 28.8494 22.4113 28.691 23.2501 28.2211C25.7815 26.8029 26.245 23.2132 26.2635 23.061C26.3086 22.6905 26.1017 22.3351 25.7572 22.1914C25.6133 22.1313 22.2026 20.7364 19.6862 22.1462C19.1462 22.4487 18.7007 22.8501 18.3332 23.2957V19.574C23.2177 19.1618 27.0633 15.1624 27.0633 10.3049C27.0633 5.17412 22.7732 1 17.4999 1C12.2266 1 7.93652 5.17412 7.93652 10.3049C7.93652 15.1624 11.7822 19.1618 16.6666 19.574V23.2958C16.2991 22.8502 15.8535 22.4488 15.3136 22.1463C12.7971 20.7365 9.38658 22.1314 9.24257 22.1915C8.8981 22.3352 8.69121 22.6906 8.73629 23.0611C8.75477 23.2132 9.2184 26.8029 11.7497 28.2212C12.5887 28.6912 13.5265 28.8494 14.41 28.8494C15.2583 28.8494 16.0556 28.7034 16.6666 28.5472V31.1896H9.56348H7.97615C7.51592 31.1896 7.14282 31.5627 7.14282 32.0229C7.14282 32.4831 7.51592 32.8562 7.97615 32.8562H8.89939L10.3384 39.1401C10.4251 39.5189 10.7622 39.7874 11.1507 39.7874H23.8491C24.2376 39.7874 24.5747 39.5188 24.6614 39.1401L26.1005 32.8562H27.0237C27.4839 32.8562 27.857 32.4831 27.857 32.0229C27.8569 31.5626 27.4839 31.1895 27.0236 31.1895ZM20.5008 23.6003C21.778 22.8846 23.545 23.2411 24.479 23.5147C24.2548 24.4318 23.693 26.0626 22.4354 26.7671C21.1578 27.4828 19.3904 27.1262 18.4571 26.8527C18.6813 25.9356 19.2432 24.3048 20.5008 23.6003ZM12.5643 26.7671C11.3068 26.0626 10.7449 24.4318 10.5207 23.5147C11.4547 23.2411 13.2217 22.8846 14.4989 23.6003C15.7569 24.305 16.3187 25.9363 16.5426 26.8526C15.6088 27.1263 13.8417 27.4828 12.5643 26.7671ZM9.60317 10.3049C9.60317 6.09312 13.1456 2.66665 17.4999 2.66665C21.8542 2.66665 25.3966 6.09312 25.3966 10.3049C25.3966 14.5167 21.8542 17.9431 17.4999 17.9431C13.1456 17.9431 9.60317 14.5166 9.60317 10.3049ZM23.185 38.1208H11.8148L10.6092 32.8561H24.3906L23.185 38.1208Z"
                    fill="#626262"
                    stroke="#626262"
                  />
                  <path
                    d="M31.418 8.82782C31.1262 8.82782 30.843 8.67434 30.6902 8.40169C30.4653 8.00018 30.6084 7.49231 31.0099 7.26738L33.7591 5.7271C34.1606 5.5021 34.6685 5.64528 34.8934 6.04679C35.1183 6.4483 34.9752 6.95618 34.5737 7.1811L31.8244 8.72138C31.6956 8.7935 31.5559 8.82782 31.418 8.82782Z"
                    fill="#02D287"
                  />
                  <path
                    d="M34.1656 14.9892C34.0277 14.9892 33.888 14.9549 33.7591 14.8828L31.0099 13.3425C30.6084 13.1175 30.4653 12.6097 30.6902 12.2082C30.9151 11.8067 31.4229 11.6636 31.8245 11.8885L34.5738 13.4288C34.9753 13.6538 35.1184 14.1616 34.8935 14.5631C34.7406 14.8357 34.4574 14.9892 34.1656 14.9892Z"
                    fill="#02D287"
                  />
                  <path
                    d="M3.58194 8.82798C3.44406 8.82798 3.30429 8.79366 3.17543 8.72154L0.426221 7.18125C0.0247103 6.95633 -0.118394 6.44846 0.106527 6.04695C0.331449 5.64544 0.83917 5.50226 1.24083 5.72726L3.99012 7.26754C4.39163 7.49246 4.53473 8.00033 4.30981 8.40185C4.15701 8.67442 3.87375 8.82798 3.58194 8.82798Z"
                    fill="#02D287"
                  />
                  <path
                    d="M0.834254 14.9887C0.542439 14.9887 0.259261 14.8352 0.10646 14.5625C-0.118462 14.1609 0.0246427 13.6532 0.426153 13.4282L3.17544 11.8879C3.57702 11.6629 4.08482 11.8061 4.30974 12.2076C4.53466 12.6091 4.39156 13.1169 3.99005 13.3419L1.24076 14.8822C1.11198 14.9543 0.972131 14.9887 0.834254 14.9887Z"
                    fill="#02D287"
                  />
                  <path
                    d="M17.384 13.815C16.3543 13.8138 15.3671 13.4041 14.639 12.676C13.9109 11.9479 13.5013 10.9607 13.5 9.93099C13.5 9.58762 13.6364 9.25831 13.8792 9.01551C14.122 8.77271 14.4513 8.63631 14.7947 8.63631C15.1381 8.63631 15.4674 8.77271 15.7102 9.01551C15.953 9.25831 16.0894 9.58762 16.0894 9.93099C16.0894 10.1871 16.1653 10.4374 16.3076 10.6503C16.4498 10.8632 16.652 11.0291 16.8886 11.1271C17.1252 11.2251 17.3855 11.2507 17.6366 11.2008C17.8878 11.1508 18.1185 11.0275 18.2995 10.8465C18.4806 10.6654 18.6039 10.4347 18.6538 10.1836C18.7038 9.93243 18.6782 9.67211 18.5802 9.43554C18.4822 9.19897 18.3162 8.99676 18.1033 8.8545C17.8904 8.71224 17.6401 8.63631 17.384 8.63631C17.0407 8.63631 16.7114 8.49991 16.4686 8.25711C16.2258 8.01431 16.0894 7.685 16.0894 7.34163C16.0894 6.99826 16.2258 6.66895 16.4686 6.42615C16.7114 6.18335 17.0407 6.04695 17.384 6.04695C18.4142 6.04695 19.4021 6.45616 20.1305 7.18456C20.8589 7.91296 21.2681 8.90088 21.2681 9.93099C21.2681 10.9611 20.8589 11.949 20.1305 12.6774C19.4021 13.4058 18.4142 13.815 17.384 13.815Z"
                    fill="#02D287"
                  />
                </svg>
                {
                  depositBal &&
                  <div>
                    <h5>Total Investment</h5>
                    <h3>{toFixed(depositBal, 2)} {currencyDoc?.currencySymbol}</h3>
                  </div>
                }
              </div>
              <div className="dash_box_right_large_single">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="37"
                  viewBox="0 0 28 37"
                  fill="none"
                >
                  <path
                    d="M13.6807 28.7681C12.6509 28.7668 11.6638 28.3572 10.9356 27.6291C10.2075 26.9009 9.7979 25.9138 9.79663 24.884C9.79663 24.5407 9.93303 24.2114 10.1758 23.9686C10.4186 23.7258 10.7479 23.5894 11.0913 23.5894C11.4347 23.5894 11.764 23.7258 12.0068 23.9686C12.2496 24.2114 12.386 24.5407 12.386 24.884C12.386 25.1401 12.4619 25.3904 12.6042 25.6033C12.7464 25.8162 12.9486 25.9822 13.1852 26.0802C13.4218 26.1782 13.6821 26.2038 13.9332 26.1538C14.1844 26.1039 14.4151 25.9806 14.5961 25.7995C14.7772 25.6185 14.9005 25.3878 14.9505 25.1366C15.0004 24.8855 14.9748 24.6252 14.8768 24.3886C14.7788 24.152 14.6129 23.9498 14.4 23.8076C14.187 23.6653 13.9367 23.5894 13.6807 23.5894C13.3373 23.5894 13.008 23.453 12.7652 23.2102C12.5224 22.9674 12.386 22.6381 12.386 22.2947C12.386 21.9513 12.5224 21.622 12.7652 21.3792C13.008 21.1364 13.3373 21 13.6807 21C14.7108 21 15.6987 21.4092 16.4271 22.1376C17.1555 22.866 17.5647 23.8539 17.5647 24.884C17.5647 25.9142 17.1555 26.9021 16.4271 27.6305C15.6987 28.3589 14.7108 28.7681 13.6807 28.7681Z"
                    fill="#02D287"
                  />
                  <path
                    d="M21.8804 15.4225H19.4597C19.1163 15.4225 18.787 15.2861 18.5442 15.0433C18.3014 14.8005 18.165 14.4712 18.165 14.1279C18.165 13.7845 18.3014 13.4552 18.5442 13.2124C18.787 12.9696 19.1163 12.8332 19.4597 12.8332H21.8804C22.1094 12.8332 22.3289 12.7422 22.4908 12.5804C22.6526 12.4185 22.7436 12.199 22.7436 11.9701C22.7436 11.7411 22.6526 11.5216 22.4908 11.3597C22.3289 11.1979 22.1094 11.1069 21.8804 11.1069H5.48117C5.25225 11.1069 5.03271 11.1979 4.87085 11.3597C4.70898 11.5216 4.61805 11.7411 4.61805 11.9701C4.61805 12.199 4.70898 12.4185 4.87085 12.5804C5.03271 12.7422 5.25225 12.8332 5.48117 12.8332H11.9546C12.2979 12.8332 12.6272 12.9696 12.87 13.2124C13.1128 13.4552 13.2492 13.7845 13.2492 14.1279C13.2492 14.4712 13.1128 14.8005 12.87 15.0433C12.6272 15.2861 12.2979 15.4225 11.9546 15.4225H5.48117C4.56551 15.4225 3.68736 15.0588 3.03989 14.4113C2.39243 13.7639 2.02869 12.8857 2.02869 11.9701C2.02869 11.0544 2.39243 10.1763 3.03989 9.52879C3.68736 8.88132 4.56551 8.51758 5.48117 8.51758H21.8804C22.7961 8.51758 23.6742 8.88132 24.3217 9.52879C24.9692 10.1763 25.3329 11.0544 25.3329 11.9701C25.3329 12.8857 24.9692 13.7639 24.3217 14.4113C23.6742 15.0588 22.7961 15.4225 21.8804 15.4225Z"
                    fill="#626262"
                  />
                  <path
                    d="M19.9299 37H7.43154C5.51064 36.9341 3.68522 36.1463 2.31966 34.7937C0.9541 33.4412 0.14886 31.6234 0.0646738 29.7032C-0.0564952 28.1221 -0.00730225 26.5325 0.211404 24.9619C1.12167 18.5879 5.91438 14.4898 7.48887 13.1437C7.74975 12.9206 8.08857 12.8102 8.43079 12.8369C8.77302 12.8636 9.09062 13.0252 9.31372 13.2861C9.53682 13.547 9.64715 13.8858 9.62044 14.228C9.59372 14.5702 9.43216 14.8878 9.17128 15.1109C7.7805 16.3011 3.5475 19.9213 2.77542 25.3234C2.58293 26.7109 2.53998 28.115 2.6473 29.5117C2.68269 30.7802 3.19472 31.9889 4.08137 32.8968C4.96802 33.8047 6.16419 34.3452 7.43154 34.4106H19.9299C21.1959 34.3458 22.391 33.8067 23.2775 32.9007C24.164 31.9946 24.6769 30.7881 24.7142 29.521C24.8218 28.1198 24.7783 26.7111 24.5844 25.3192C24.1366 22.575 22.9135 20.016 21.0594 17.9439C20.1804 16.9237 19.2203 15.9763 18.1885 15.1109C17.9285 14.8871 17.768 14.5693 17.742 14.2273C17.716 13.8852 17.8268 13.5468 18.05 13.2863C18.2732 13.0259 18.5907 12.8645 18.9326 12.8378C19.2746 12.811 19.6133 12.921 19.8743 13.1437C21.0092 14.0982 22.0651 15.1428 23.0318 16.2674C25.206 18.7113 26.6346 21.7271 27.1484 24.9576C27.3686 26.5326 27.4183 28.1267 27.2968 29.7123C27.2107 31.631 26.4046 33.4468 25.0392 34.7975C23.6738 36.1482 21.8494 36.9347 19.9299 37Z"
                    fill="#626262"
                  />
                  <path
                    d="M16.9023 11.1064H10.4592C10.2584 11.1067 10.0604 11.0599 9.88094 10.9699C9.67528 10.8679 4.86742 8.41848 4.86742 4.44167C4.82604 3.4672 5.11527 2.50735 5.68815 1.71797C6.26103 0.928589 7.08392 0.356036 8.02321 0.0932735C8.8139 -0.0922282 11.3021 -0.334143 13.6808 2.99021C16.0611 -0.334143 18.5476 -0.0922281 19.3382 0.0932045C20.2775 0.355967 21.1004 0.92852 21.6733 1.7179C22.2462 2.50728 22.5354 3.46713 22.494 4.4416C22.4691 5.97377 21.9378 7.45461 20.983 8.65319C20.0282 9.85176 18.7036 10.7007 17.2158 11.0676C17.1133 11.0935 17.008 11.1066 16.9023 11.1064ZM10.7846 8.51705H16.7185C17.6152 8.26702 18.4082 7.73649 18.9816 7.00314C19.5549 6.2698 19.8784 5.37216 19.9047 4.44167C19.9414 4.05124 19.8472 3.65969 19.6369 3.32868C19.4267 2.99768 19.1122 2.74605 18.7432 2.61345C17.8429 2.39851 16.292 3.19001 14.8389 6.09458C14.7192 6.29502 14.5496 6.46097 14.3466 6.57621C14.1436 6.69145 13.9142 6.75203 13.6808 6.75203C13.4473 6.75203 13.2179 6.69145 13.0149 6.57621C12.8119 6.46097 12.6423 6.29502 12.5226 6.09458C11.0695 3.1916 9.52024 2.40019 8.61835 2.61345C8.2493 2.74605 7.93486 2.99768 7.72457 3.32868C7.51428 3.65969 7.42009 4.05124 7.45685 4.44167C7.45685 6.26147 9.66017 7.88234 10.7846 8.51705Z"
                    fill="#626262"
                  />
                  <path
                    d="M15.407 15.4224C16.122 15.4224 16.7017 14.8427 16.7017 14.1277C16.7017 13.4127 16.122 12.833 15.407 12.833C14.692 12.833 14.1123 13.4127 14.1123 14.1277C14.1123 14.8427 14.692 15.4224 15.407 15.4224Z"
                    fill="#626262"
                  />
                </svg>
                {
                  comissionBal &&
                  <div>
                    <h5>Direct Referral Income</h5>
                    <h3>{toFixed(comissionBal, 2)} {currencyDoc?.currencySymbol}</h3>
                  </div>
                }
              </div>
              <div className="dash_box_right_large_single">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M32.2482 20.3301C32.0102 20.3299 31.7772 20.2622 31.5763 20.1347C31.3753 20.0072 31.2148 19.8253 31.1132 19.6101C31.0117 19.3949 30.9734 19.1552 31.0028 18.9191C31.0322 18.683 31.1281 18.4601 31.2793 18.2763C32.2659 17.0863 32.9515 15.6762 33.2782 14.1653C33.605 12.6545 33.5633 11.0871 33.1567 9.59576C32.7501 8.10439 31.9905 6.73279 30.9421 5.59685C29.8937 4.46091 28.5873 3.59399 27.1332 3.06935C25.6792 2.5447 24.1202 2.37774 22.588 2.58257C21.0558 2.78739 19.5955 3.35799 18.3303 4.24615C17.0651 5.13431 16.0322 6.31394 15.319 7.68538C14.6058 9.05683 14.2332 10.5798 14.2326 12.1256C14.2225 14.0832 14.8214 15.9955 15.9462 17.5977C16.0415 17.7326 16.1092 17.8849 16.1455 18.0459C16.1819 18.2069 16.1861 18.3735 16.158 18.5362C16.1299 18.6989 16.07 18.8544 15.9818 18.994C15.8935 19.1335 15.7787 19.2542 15.6437 19.3493C15.5088 19.4444 15.3564 19.5119 15.1953 19.5481C15.0342 19.5843 14.8676 19.5883 14.705 19.5601C14.5423 19.5318 14.3869 19.4717 14.2474 19.3833C14.108 19.2949 13.9874 19.1799 13.8925 19.0448C12.469 17.0192 11.7102 14.6013 11.7209 12.1256C11.7232 10.1785 12.1937 8.26051 13.0929 6.53346C13.9921 4.80642 15.2935 3.32101 16.8873 2.20256C18.4812 1.08412 20.3206 0.365462 22.2505 0.107234C24.1804 -0.150993 26.1441 0.058786 27.9759 0.71887C29.8077 1.37895 31.4539 2.46998 32.7755 3.89987C34.097 5.32976 35.0553 7.05657 35.5694 8.93459C36.0834 10.8126 36.1382 12.7867 35.7291 14.6903C35.3199 16.594 34.4589 18.3713 33.2186 19.8722C33.1008 20.0156 32.9527 20.131 32.7848 20.2102C32.617 20.2894 32.4337 20.3303 32.2482 20.3301Z"
                    fill="#02D287"
                  />
                  <path
                    d="M6.01582 35.986H2.85911C2.10111 35.985 1.37442 35.6835 0.838437 35.1475C0.30245 34.6115 0.00092609 33.8848 0 33.1268V17.9147C0.00094381 17.1567 0.302476 16.4301 0.838461 15.8941C1.37445 15.3581 2.10112 15.0566 2.85911 15.0557H6.01582C6.77372 15.0566 7.5003 15.3582 8.03613 15.8942C8.57197 16.4302 8.87332 17.1569 8.87406 17.9148V33.1269C8.8733 33.8848 8.57195 34.6115 8.03611 35.1474C7.50027 35.6834 6.77371 35.985 6.01582 35.986ZM2.85911 17.5673C2.76702 17.5675 2.67876 17.6042 2.61364 17.6693C2.54852 17.7344 2.51185 17.8227 2.51163 17.9148V33.1269C2.51185 33.219 2.54852 33.3073 2.61364 33.3724C2.67876 33.4375 2.76702 33.4742 2.85911 33.4744H6.01582C6.10782 33.4741 6.19597 33.4374 6.26094 33.3723C6.32592 33.3072 6.36241 33.2189 6.36242 33.1269V17.9148C6.36241 17.8228 6.32592 17.7345 6.26094 17.6694C6.19597 17.6043 6.10782 17.5675 6.01582 17.5673H2.85911Z"
                    fill="#626262"
                  />
                  <path
                    d="M22.5933 33.9768H14.6512C14.3181 33.9768 13.9987 33.8445 13.7632 33.6089C13.5277 33.3734 13.3954 33.054 13.3954 32.7209C13.3954 32.3879 13.5277 32.0685 13.7632 31.8329C13.9987 31.5974 14.3181 31.4651 14.6512 31.4651H22.5933C23.3082 31.4671 24.0164 31.3272 24.677 31.0537C25.3376 30.7802 25.9374 30.3784 26.4417 29.8716L32.7199 23.5926C33.0655 23.2469 33.2727 22.7867 33.3026 22.2988C33.3325 21.8109 33.1829 21.3289 32.8821 20.9437C32.5812 20.5584 32.1499 20.2964 31.6693 20.2071C31.1887 20.1178 30.6921 20.2073 30.2729 20.4587L22.7903 24.9489C22.0745 25.3782 21.2556 25.6048 20.421 25.6046H14.9496C14.6166 25.6046 14.2971 25.4723 14.0616 25.2368C13.8261 25.0013 13.6938 24.6819 13.6938 24.3488C13.6938 24.0158 13.8261 23.6963 14.0616 23.4608C14.2971 23.2253 14.6166 23.093 14.9496 23.093H20.4209C20.8004 23.0932 21.1729 22.9903 21.4985 22.7954L28.9811 18.3052C29.9258 17.7382 31.0453 17.5363 32.1286 17.7376C33.2119 17.9388 34.1842 18.5293 34.8623 19.3978C35.5404 20.2663 35.8774 21.3528 35.8099 22.4525C35.7424 23.5523 35.275 24.5894 34.4957 25.3684L28.2175 31.6475C27.4805 32.3882 26.604 32.9754 25.6386 33.3752C24.6732 33.7751 23.6382 33.9795 22.5933 33.9768Z"
                    fill="#626262"
                  />
                  <path
                    d="M18.6696 25.6043C18.3366 25.6042 18.0172 25.4719 17.7817 25.2364C17.5462 25.0009 17.4139 24.6815 17.4138 24.3485V21.3349C17.4134 20.8688 17.2281 20.4219 16.8985 20.0923C16.5689 19.7628 16.1221 19.5775 15.656 19.5771H8.87406V24.3485C8.87406 24.6816 8.74175 25.001 8.50624 25.2365C8.27073 25.472 7.95131 25.6043 7.61824 25.6043C7.28518 25.6043 6.96576 25.472 6.73025 25.2365C6.49474 25.001 6.36243 24.6816 6.36243 24.3485V18.3212C6.36251 17.9882 6.49485 17.6688 6.73034 17.4333C6.96583 17.1979 7.28521 17.0655 7.61824 17.0654H15.656C16.7879 17.0668 17.873 17.5171 18.6734 18.3175C19.4737 19.1179 19.924 20.203 19.9254 21.3349V24.3485C19.9253 24.6815 19.793 25.0009 19.5575 25.2364C19.322 25.4719 19.0026 25.6042 18.6696 25.6043Z"
                    fill="#626262"
                  />
                  <path
                    d="M11.3023 33.977C11.9959 33.977 12.5581 33.4147 12.5581 32.7211C12.5581 32.0276 11.9959 31.4653 11.3023 31.4653C10.6088 31.4653 10.0465 32.0276 10.0465 32.7211C10.0465 33.4147 10.6088 33.977 11.3023 33.977Z"
                    fill="#626262"
                  />
                </svg>
                {
                  profitBal &&
                  <div>
                    <h5>Daily Income</h5>
                    <h3>{toFixed(profitBal, 2)} {currencyDoc?.currencySymbol}</h3>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default WalletDetails;
import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import NavbarFixed from "../components/Navbar-Fixed.js";
import { Link, NavLink } from "react-router-dom";
import {GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from '../config';



// components
import LoginForm from "../components/Login";

const RECAPTCHA_SITE_KEY =config.RECAPTCHA_SITE_KEY;


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Login(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="auth_wrapper">
          {/* <LoginForm /> */}
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
            <LoginForm />
          </GoogleReCaptchaProvider>
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}

// import package
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SocketContext from '../../context/SocketContext';

// import action
import { viewUserProfile, logout } from '../../api/users';
import { getAssetData } from '../../api/walletAction';
import { getRefLevList, getRefLevSettings } from '../../api/referralAction';
import { unReadNotice, FetchunReadNotice } from '../../api/users';

// import lib
import isEmpty from '../../lib/isEmpty';

const HelperRoute = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socketContext = useContext(SocketContext);

    // redux-state
    const { isAuth } = useSelector(state => state.auth);
    // const currencyOption = useSelector(state => state.currency)

    // function
    useEffect(() => {
        if (isAuth) {
            // getUserSetting(dispatch)
            // getBankDetail(dispatch)
            // getUPIDetail(dispatch)
            // getQRDetail(dispatch)
            viewUserProfile(dispatch)
            getAssetData(dispatch)
            getRefLevList(dispatch)
            // getPriceConversion(dispatch)
            // getInstantPair(dispatch)
            // getANNC(dispatch)
            unReadNotice(dispatch)
            // getKycDetail(dispatch)
        }
        getRefLevSettings(dispatch)
    }, [isAuth])

    useEffect(() => {
        
        socketContext.socket.on('notice', (result) => {
            FetchunReadNotice(dispatch, result)
        })

        socketContext.socket.on('NEW_DEPOSIT', (result) => {
            getAssetData(dispatch)
        })

        socketContext.socket.on('FORCE_LOGOUT', (result) => {
            let token = localStorage.getItem('user_token');
            if(!isEmpty(token) && !isEmpty(result) && token != result){
                logout(navigate, dispatch)
            }
        })
    }, [])

    return <div />
}

export default HelperRoute;
import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";

// import api
import { getTrnxHistory } from '../../api/walletAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

// Latest Transaction Table Data
const columns = [
  {
    name: "Date",
    selector: (row) => row.createdAt,
    cell: (record) =>  `${dateTimeFormat(record.createdAt)}`
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
  },
  {
    name: "User Id",
    selector: (row) => row.userCode,
  },
  {
    name: "Type",
    selector: (row) => row.paymentType,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
];


const TransactionHistory = () => {

  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ 'page' : 1, 'limit' : 10 })

  const { page, limit } = pageDoc;

  const getData = async () => {
      try {
          const { status, result } = await getTrnxHistory({ page, limit });
          if(status === 'success') {
             setData(result.data)
             setCount(result.count)
          }
      } catch (err) { }
  }

  useEffect(() => { 
    getData() 
  }, [page, limit]);

	return (
		<div className="dash_box">
      <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
        <h3>Latest Transaction</h3>
      </div>
      <div className="primary_datatable">
        <DataTable
          columns={columns}
          data={data}
          pagination 
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangePage={(page) => setPageDoc({...pageDoc, ... { 'page' : page } })}
          onChangeRowsPerPage={(limit) => setPageDoc({...pageDoc, ... { 'limit' : limit } })}
        />
      </div>
    </div>
	)
}

export default TransactionHistory;
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

export default function MyTimer({ expiryTimestamp, fetchData }) {
  const [expiry, setExpiry] = useState(expiryTimestamp);

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: expiry,
    onExpire: () => {
      fetchData();
    },
  });

  useEffect(() => {
    setExpiry(expiryTimestamp);
  }, [expiryTimestamp]);

  useEffect(() => {
    restart(expiry);
  }, [expiry]);

  function padWithZero(num) {
    return num < 10 ? "0" + num : num.toString();
  }

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center px3">
      <div className="d-flex align-items-center justify-content-center w-100 gap-3 fw-regular font-20 px-3 py-2 counter">
        <div className="rounded-3 time-card text-center">
          <div className="count_value">{padWithZero(hours)}</div>
          <div className="count_indicator">HRS</div>
        </div>
        <div className="rounded-3 time-card text-center">
          <div className="count_value">{padWithZero(minutes)}</div>
          <div className="count_indicator">MINS</div>
        </div>
        <div className="rounded-3 time-card text-center">
          <div className="count_value">{padWithZero(seconds)}</div>
          <div className="count_indicator">SEC</div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";

// import api
import { getLoginHistory } from '../../api/users';

// Login History
const columns = [
  {
    name: "Country Code",
    selector: (row) => row.countryCode,
  },
  {
    name: "Country/Region",
    selector: (row) => row.countryName,
    cell: (record) =>  `${record.countryName} / ${record.regionName}`
  },
  {
    name: "Ip Address",
    selector: (row) => row.ipAddress,
  },
  {
    name: "Broswer",
    selector: (row) => row.broswerName,
  },
  {
    name: "status",
    selector: (row) => row.status,
  },
];

const LoginHistory = () => {

  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ 'page' : 1, 'limit' : 10 })

  const { page, limit } = pageDoc;

  const getData = async () => {
      try {
          const { status, result } = await getLoginHistory({ page, limit });
          if(status === 'success') {
             setData(result.data)
             setCount(result.count)
          }
      } catch (err) { }
  }

  useEffect(() => { 
    getData() 
  }, [page, limit]);

	return (
	   <div className="dash_box">
        <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
          <h3>Login Activity</h3>
        </div>
        <div className="primary_datatable">
          <DataTable
            columns={columns}
            data={data}
            pagination 
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangePage={(page) => setPageDoc({...pageDoc, ... { 'page' : page } })}
            onChangeRowsPerPage={(limit) => setPageDoc({...pageDoc, ... { 'limit' : limit } })}
          />
        </div>
      </div>
	)
}

export default LoginHistory;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

// api
import { resetPassword } from '../../api/users';

// lib
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
	"password" : "",
	"confirmPassword" : ""
}

const ResetPassword = () => {
    
    const navigate = useNavigate()
    const { authToken } = useParams()

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [errors, setErrors] = useState({});
    const { password, confirmPassword } = formValue;
	
	const handleChange = (e) => {
		const { name, value, checked } = e.target;
		setFormValue({ ...formValue, ... { [name] : value } })
	}

	const handleSubmit = async (e) => {
		try {

			const { status, message, errors } = await resetPassword({ authToken, password, confirmPassword });
			if (status == 'success') {
	            setFormValue(initialFormValue)
	            toastAlert('success', message, 'signup', 'TOP_CENTER');
	            navigate('/trade')
	        } else {
	            if (errors) {
	                setErrors(errors);
	                return false;
	            }
	            toastAlert('error', message, 'signup', 'TOP_CENTER');
	        }
		} catch (err) { }
	}

	return (		
	    <div className="container">            
	        <div className="row">
	          <div className="col-xl-6 m-auto">
	            <div className="dash_box mb-0">
	              <div className="inner_title_wrapper mt-2">
	                <h2 className="inner_title">Reset Password</h2>
	              </div>
	              <form className="auth_form">
	                <div class="mb-3"> 
	                  <label for="exampleFormControlInput1" class="form-label">
	                    New Password
	                  </label>
	                  <input
	                    type="password"
	                    class="form-control"
	                    id="exampleFormControlInput1"
	                    placeholder="Enter New Password"
	                    name="password"
	                    onChange={handleChange}
	                    value={password}
	                  />
	                  <span className='text-danger'>{errors?.password}</span>
	                </div>
	                <div class="mb-3"> 
	                  <label for="exampleFormControlInput1" class="form-label">
	                    Confirm Password
	                  </label>
	                  <input
	                    type="password"
	                    class="form-control"
	                    id="exampleFormControlInput1"
	                    placeholder="Enter Confirm Password"
	                    name="confirmPassword"
	                    onChange={handleChange}
	                    value={confirmPassword}
	                  />
	                  <span className='text-danger'>{errors?.confirmPassword}</span>
	                </div>                    
	                <div className="text-center mt-4 mb-4">
	                  <button 
	                  	type="button"
	                  	class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
	                  	onClick={handleSubmit}
	                  >
	                    Submit
	                  </button>
	                </div>                    
	              </form>
	            </div>
	          </div>
	        </div>
	    </div>
	)
}

export default ResetPassword;
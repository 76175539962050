import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";

// import components
import AccountSettings from '../components/AccountSettings';
import LoginHistory from '../components/AccountSettings/LoginHistory';
import ProfileModal from '../components/AccountSettings/ProfileModal';
import ChangePassword from '../components/AccountSettings/ChangePassword';
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AccountPage() {

  return (
    <div>
      <ScrollToTopOnMount/>
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper">
            <h2 className="inner_title">Account Settings</h2>
          </div>
          <AccountSettings />
          <LoginHistory />
        </div>
      </div>
      <Footer />
      <NavbarFixed />
      <ProfileModal />
      <ChangePassword />
    </div>
  );
}

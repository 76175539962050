import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';

// import bimages
import copy_icon from "../../assets/images/copy_icon.svg";

// import lib
import { textCopy } from '../../lib/toastAlert';

const Deposit = () => {

    // redux-state
    const walletData = useSelector((state) => state.wallet);

    // state
    const [walletAddress, setWalletAddress] = useState('');
    const [currencyDoc, setCurrencyDoc] = useState({});

    useEffect(() => {
       if(walletData && walletData.length > 0) {
            let walletDoc = walletData[0];
            setWalletAddress(walletDoc?.currencyAddress)
            setCurrencyDoc(walletDoc.currency)
       }
    }, [walletData])

  	return (
  		<div className="dash_wrapper">
        <div className="row">
          <div className="col-lg-12">
            <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
              <div className="asset_dashbox_flex_left">
                <h3 className="asset_deposit_innertitle">Deposit Terms</h3>
                <div className='d-none d-lg-block'>
                <div
                  role="alert"
                  className="primary_warning_alert alert alert-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                      fill="#38DCA1"
                    />
                  </svg>
                  <div className="alertCard">
                    <h3>Minimum deposit amount: {currencyDoc?.minimumDeposit} {currencyDoc?.currencySymbol}</h3>
                    <p>
                      Deposits less than the minimum amount will not be
                      accepted and refunds are not allowed
                    </p>
                  </div>
                </div>
                </div>
                <ul className="deposit_notes_list">
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                </ul>
              </div>
              <div className="asset_dashbox_flex_right">
                <h3 className="asset_deposit_innertitle">{currencyDoc?.currencySymbol} Deposit</h3>
                <div className="text-center my-3">
                  { walletAddress && <QRCode value={walletAddress} /> }
                </div>
                <form className="assets_form">
                  <div className="mb-3">
                    <label for="depositaddress" className="form-label">
                      Deposit Address
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type="text"
                        className="form-control primary_asset_inp"
                        id="depositaddress"
                        value={walletAddress}
                        disabled
                      />
                      <img
                        src={copy_icon}
                        alt="Copy"
                        className="asset_copy_icon"
                        onClick={() => textCopy(walletAddress)}
                      />
                    </div>
                  </div>
                  <div className="deposit_asset_details">
                    <div>
                      <label>chain network</label>
                      <label>{currencyDoc?.tokenType?.toUpperCase()}</label>
                    </div>
                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button className="w-100 primary_btn primary_btn_with_bg primary_btn_transform">
                      Deposit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  	)
}

export default Deposit;
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

const AccountSettings = () => {


	// redux-state
	const { userName, loginHistory, profile } = useSelector((state) => state.account);
	return (
	   <div className="dash_wrapper">
        <div className="row">
          <div className="col-lg-12">
            <div className="dash_box">
              <div class="user_info">
                <img
                  alt="arrow"
                  class="img-fluid"
                  src={profile ? profile : require("../../assets/images/copy_usericon.png")}
                />
                <div class="user_info_content">
                  <h3>{userName}</h3>
                  <h5>
                    { (loginHistory && loginHistory.createdAt) && <span>Last Login: { dateTimeFormat(loginHistory.createdAt) }</span> }
                  </h5>
                  <h6>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#editprofile_modal"
                    >
                      Edit Profile
                    </span>{" "}
                    <span className="center_line">|</span>{" "}
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#changepassword_modal"
                    >
                      Change Password
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	)
}

export default AccountSettings;
import React, { useEffect } from "react";


// import components
import Footer from "../components/Footer";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed";
import Wallet from "../components/Wallet";
import InternalTransfer from "../components/Wallet/InternalTransfer";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function WalletPage(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <Wallet />
      <Footer />
      <NavbarFixed />

      {/* Transfer Modal */}
      <InternalTransfer />
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';

// import lib
import config from '../../config';

// Images
import copy_icon from "../../assets/images/copy_icon.svg";

import { textCopy } from '../../lib/toastAlert';
import { substring } from '../../lib/stringCase';

const ContentNew = () => {
    // redux-state
    const { referralCode } = useSelector((state) => state.account)
    const referralSetting = useSelector((state) => state.referralSettings);
    const { dailyIncomePerc, refIncomePerc, initialDepositPerc } = referralSetting;

    const refLink = `${config.FRONT_URL}/register?ref=${referralCode}`;

    return (
        <div className="row level_top_row">
            <div className="col-lg-12">

                <div class="row">
                    <div className="col-lg-12">
                        <div className="dash_box dash_box_right_flex dash_box_right">
                            <div className='pb-3' >
                                <h3>Affiliate Link</h3>
                                {refLink &&
                                    <div className="affiliate_inpgrp">
                                        <input
                                            className=""
                                            value={refLink}
                                        />
                                        <img src={copy_icon} alt="Copy" className="copy_icon" onClick={() => textCopy(refLink)} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div className="col-lg-12">
                        <div className="dash_box dash_box_right_flex dash_box_right">
                            <div className='pb-3' >
                                <h3>Direct Referral Income</h3>
                                {initialDepositPerc && <h4>If any user joins through your referral link, you will get {initialDepositPerc}% direct income of his total investment, which will be given from the Company and will not be taken from the user's balance. </h4>}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div className="col-lg-12">
                        <div className="dash_box dash_box_right_flex dash_box_right">
                            <div className='pb-3' >
                                <h3>Daily Referral Income
                                </h3>
                                {refIncomePerc && <h4>If any user joins through your referral link, you will get {refIncomePerc}% of the user's profit, which will be given from the Company and it will not be taken from the user's balance.</h4>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentNew;
import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";

// import api
import { logout } from '../api/users';

export default function NavbarInner(props) {

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux-state
  const { notifiyCount } = useSelector((state) => state.notice)


  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(document).ready(function () {
      $(".nav_after_login li a").click(function () {
        $("body").css({ overflow: "unset" });
      });
    });
  }

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <>
      <div className="container">
        <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <ul className="navbar-nav navbar_left">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/trade">
                Trade
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/level">
                Level
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/wallet" className="nav-link">
                Wallet
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/referral" className="nav-link">
                Referrals
              </NavLink>
            </li>
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto">
                <ul class="navbar-nav mb-2 mb-lg-0 nav_after_login d-none d-lg-flex">
                  <li class="nav-item">
                    <Link class="nav-link" to="/notifications">
                      <span className="notify_active">{notifiyCount}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                      </svg>
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                      >
                        <path
                          d="M4.10256 4.87179C4.10256 2.18564 6.28821 0 8.97436 0C11.6605 0 13.8462 2.18564 13.8462 4.87179C13.8462 7.55795 11.6605 9.74359 8.97436 9.74359C6.28821 9.74359 4.10256 7.55795 4.10256 4.87179ZM12.0513 11.2821H5.89744C2.64615 11.2821 0 13.9282 0 17.1795C0 18.7354 1.26462 20 2.82051 20H15.1282C16.6841 20 17.9487 18.7354 17.9487 17.1795C17.9487 13.9282 15.3026 11.2821 12.0513 11.2821Z"
                          fill="#1E1E1E"
                        />
                      </svg>
                      My Account
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/account-settings">
                          Account Settings
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" onClick={() => logout(navigate, dispatch)}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="navbar-nav mb-2 mb-lg-0 nav_after_login d-md-block    d-lg-none">
                  <li class="nav-item">
                    <Link class="nav-link" to="/account-settings">
                      Account Settings
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/notifications">
                      Notifications
                      <span className="notify_active">56</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="dropdown-item" onClick={() => logout(navigate, dispatch)}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

import React, { useState, useRef } from 'react';

// import api
import { changePassword } from '../../api/users';

// import lib
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = { 
  "oldPassword" : "", 
  "password" : "", 
  "confirmPassword" : "" 
}

const initialPassWordIcon = {
   "oldPasswordIcon" : false,
   "passwordIcon" : false, 
   "confirmPasswordIcon" : false 
}

const ChangePassword = () => {
	
  const modalRef = useRef();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [passwordIcons, setPasswordIcons] = useState(initialPassWordIcon);
  const [errors, setErrors] = useState({});

  const { oldPassword, password, confirmPassword } = formValue;
  const { oldPasswordIcon, passwordIcon, confirmPasswordIcon } = passwordIcons;

  const handleChange = (e) => {
     const { name, value } = e.target;
     setFormValue({ ...formValue, ... { [name] : value } })
  }

  const handlePassIcon = (name) => setPasswordIcons({ ...passwordIcons, ... { [name] : !passwordIcons[name] } })

  const handleCloseModal = () => {
     setErrors({})
     setFormValue(initialFormValue)
     setPasswordIcons(initialPassWordIcon)
     modalRef.current.click()
  };

  const handleSubmit = async () => {
     try {
        
        const { status, message, errors } = await changePassword({ oldPassword, password, confirmPassword });
        
        if(status === 'success') {
            toastAlert('success', message, 'changePassword')
            handleCloseModal()
        } else {
          errors && setErrors(errors)
        }
     } catch (err) {}
  }

  return (
	  <div
        className="modal fade primary_modal"
        data-bs-backdrop="static"
        id="changepassword_modal"
        tabindex="-1"
        aria-labelledby="changepassword_modalLabel"
        aria-hidden="true"
      >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="changepassword_modalLabel">
              Change Password
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
              onClick={handleCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            <form className="assets_form">
              <div className="mb-3">
                <label for="oldpassword" className="form-label">
                  Old Password
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!oldPasswordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="oldPassword"
                    placeholder="Enter Old Password"
                    value={oldPassword}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={oldPasswordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill" } onClick={() => handlePassIcon('oldPasswordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{errors?.oldPassword}</span>
              </div>

              <div className="mb-3">
                <label for="newpassword" className="form-label">
                  New Password
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!passwordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="password"
                    placeholder="Enter New Password"
                    value={password}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={passwordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill" } onClick={() => handlePassIcon('passwordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{errors?.password}</span>
              </div>

              <div className="mb-3">
                <label for="cnewpassword" className="form-label">
                  Confirm New Password
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!confirmPasswordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="confirmPassword"
                    placeholder="Enter Confirm New Password"
                    value={confirmPassword}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={confirmPasswordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill" } onClick={() => handlePassIcon('confirmPasswordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{errors?.confirmPassword}</span>
              </div>

              <div className="asset_modal_btn_grp mt-4 mb-4">
                <button
                  className="w-100 primary_btn primary_btn_transform"
                  // data-bs-dismiss="modal"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button 
                  className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                  type="button"
                  onClick={handleSubmit}
                >
                  Change
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
	)
}

export default ChangePassword;
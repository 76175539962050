import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// import api
import { emailVerify } from '../api/users';
import { coinRequestVerify } from '../api/walletAction';

// import lib
import { toastAlert } from '../lib/toastAlert';

const EmailVerification = () => {
	
	const { authToken } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const emailActivation = async () => {
		 try {
		 	const { status, message } = await emailVerify({ userId : authToken });
		 	toastAlert(status === 'success' ? status : 'error', message, 'emailVerify')
		 	navigate('/login')
		 } catch (err) {}
	}

	const acceptCoinRequest = async () => {
		 try {
		 	const { status, message } = await coinRequestVerify({ authToken });
		 	toastAlert(status === 'success' ? status : 'error', message, 'coinRequestVerify')
		 	navigate('/')
		 } catch (err) {}
	}

	useEffect(() => {
		let pathname = location.pathname;
	    if (pathname == "/email-verification/" + authToken) {
	      emailActivation();
	    } else if (pathname == "/withdraw-verification/" + authToken) {
	      acceptCoinRequest();
	    }
	}, [])

	return (
		<h3>Loading...</h3>
	)
}

export default EmailVerification;
import React from 'react';

// import components
import Content from './content';
import Content_Wthout_Login from './contentLogin';
import { useSelector } from 'react-redux';

const ReferralIncome = () => {

    // dispatch
    const { isAuth } = useSelector((state) => state.auth);

    return (
        <div className="innerpages_wrapper">
            <div className="container">
                <div className="inner_title_wrapper">
                    <h2 className="inner_title">Referral Income</h2>
                </div>
                <div className="dash_wrapper">
                    {isAuth ? <Content /> : <Content_Wthout_Login />}
                </div>
            </div>
        </div>
    )
}

export default ReferralIncome;
import React from 'react';
import { useSelector } from 'react-redux';

const LevelList = () => {
	
	// redux-state
	const referralLevel = useSelector((state) => state.referralLevel);

	return (
		<div className="row">
          <div className="col-md-12 col-lg-10 col-xl-9 m-auto">
            <div className="level_table">
              <div className="level_head">
                <div>Level</div>
                <div>Amount($)</div>
                <div>Reward</div>
              </div>
              <div className="level_body">
                {
                	referralLevel && referralLevel.length > 0 && referralLevel.map((item, key) => {
                		return (
                			<div className="level_body_row" key={key}>
			                  <div className="level_ribbon active">Level-{item.levelNo}</div>
			                  <div>{item.reachAmount}</div>
			                  <div>{item.rewardAmount}$</div>
			                </div>
                		)
                	})
                }
              </div>
            </div>
          </div>
        </div>
	)
}

export default LevelList;
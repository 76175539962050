import React, { useEffect } from "react";

// import components
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed";
import Level from "../components/Level";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function LevelPage() {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <Level />
      <Footer />
      <NavbarFixed />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { getSiteSetting } from "../api/users";

export default function Footer(props) {

  const [siteDoc, setSiteDoc] = useState({});

  const fetchData = async () => {
    let { status, result } = await getSiteSetting();
    if (status == 'success') {
      setSiteDoc(result)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <Link className="navbar-brand" to="/">
                <img src={logo} className="img-fluid mb-4" alt="logo" />
              </Link>
              <p className="footer_abt_content">
                COPY UNIT is the Trading Platform where you can Earn daily income By trading and Earn Handsome Commissions from Refferals.
              </p>
            </div>
            <div className="col-lg-3 col-md-3">
              <h3>Quick Links</h3>
              <ul className="footer_quick_links">
                <li>
                  <Link to="/trade">Trade</Link>
                </li>
                <li>
                  <Link to="/level">Level</Link>
                </li>
                <li>
                  <Link to="/wallet">Wallet</Link>
                </li>
                <li>
                  <Link to="/referral">Referrals</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3">
              <h3>Follow us</h3>
              <ul className="footer_social_links">
                {siteDoc && siteDoc.facebookLink && <li>
                  <a href={siteDoc.facebookLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.twitterUrl && <li>
                  <a href={siteDoc.twitterUrl} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.telegramlink && <li>
                  <a href={siteDoc.telegramlink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                    </svg>
                  </a>
                </li>
                }
                {siteDoc && siteDoc.linkedinLink && <li>
                  <a href={siteDoc.linkedinLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                    </svg>
                  </a>
                </li>}
                {siteDoc && siteDoc.whatsappLink && <li>
                  <a href={siteDoc.whatsappLink} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                  </a>
                </li>}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            Copyright © {new Date().getFullYear()} <a href="/">Copy Unit</a>,
            All rights Reserved
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">Terms of service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
